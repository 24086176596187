import React, {useEffect, useState} from "react";
import Input from "@core/inputs/Input";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import TextArea from "@core/textareas/TextArea";
import {showError, showSuccess} from "@utils/helpers";
import API from "@utils/plugins/API";

const WorkersForm = () => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const submit = () => {
    setLoading(true);
    API.post('report-abuse/workers', {
      phone,
      email,
      text,
    })
      .then((res) => {
        setPhone('')
        setEmail('')
        setText('')
        showSuccess('Të dhënat u ruajtën')
    }).catch(() => {
      showError('Gabim! Ju lutem kontrolloni formën')
    }).finally(() => setLoading(false))
  }

  return (
    <div>
      <Input type={'tel'} value={phone} className={'my-2'} label={'Telefon'} onChange={setPhone} />
      <Input type={'email'} value={email} className={'my-2'} label={'Email'} onChange={setEmail} />
      <TextArea value={text} className={'mt-2 mb-5'} label={'Pershkruaj denoncimin'} onChange={setText} />
      <DefaultButton loading={loading} label={'Dergo'} onClick={submit}  />
      <div className={'text-xs text-primary mt-5'}>*Denoncimet e raportuara do të trajtohen në mënyrë konfidenciale nga institucionet kompetente!</div>
    </div>
  );
}

export default WorkersForm;
