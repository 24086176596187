import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Item = ({isActive = false, data, onClick}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const handleClick = () => {
    if (innerWidth < 769) {
      return history.push(data.url)
    }
    onClick(data)
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`flex-1 border-b flex px-5 lg:px-10 items-center cursor-pointer ${isActive && innerWidth > 769 ? 'bg-green text-white' : 'text-primary bg-white hover:bg-gray-200'} duration-500 transition-all`} onClick={handleClick}>
      <div>
        {data.svg}
      </div>
      <p className={'text-lg font-light ml-5'}>{t(data.title)}</p>
    </div>
  )
}

const PageChildrenPreview = ({items}) => {
  const {t} = useTranslation()
  const [activeItem, setActiveItem] = useState({})
  useEffect(() => {
    setActiveItem(items[0])
  }, [])

  return (
    <div>
      <div className={'h-15 bg-primary hidden md:block'}/>
      <div className={'h-120 flex'}>
        <div className={'flex-1 bg-white flex flex-col'}>
          {items.map((item, index) => (
            <Item
              key={index}
              isActive={item.url === activeItem.url}
              data={item}
              onClick={setActiveItem}
            />
          ))}
        </div>
        <div className={'flex-1 bg-primary px-10 hidden md:block'} style={{borderRadius: '0 0 200px 0'}}>
          <h1 className={'text-2xl text-white font-extrabold'}>{t(activeItem.title)}</h1>
          <p className={'text-lg text-white font-thin pr-10 lg:pr-20 my-8 lg:my-10'}>{t(activeItem.description)}</p>
          <Link to={activeItem.url} className={'px-5 py-3 bg-white text-primary hover:bg-green hover:text-white duration-500 transition-all'} style={{borderRadius: '0 20px 20px 20px'}}>
            {t('readMore')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PageChildrenPreview;
