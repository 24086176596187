import React, {useMemo, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import PageHeader from "@components/Pages/PageHeader";
import PageListItem from "@components/Pages/ListItem";
import Pagination from "@components/Pages/Pagination";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from "lodash";

const PAGE_SIZE = 5;

const ExpropriationPage = () => {
  const {t, i18n} = useTranslation()
  const language = i18n.language;
  const data = useSelector((state) =>
    _.get(state, "generalState.expropriation", [])
  );
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);

  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <PageHeader title={t('publications.expropriation')} />
        {currentTableData.map((item) => (
          <PageListItem key={item.id} name={item[`title_${language}`]} to={`/publications/expropriation/${item.id}`} />
        ))}
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PAGE_SIZE}
          onPageChange={setCurrentPage}
        />
      </div>
    </Layout>
  );
}

export default ExpropriationPage;
