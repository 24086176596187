import React from "react";
import {Link} from "react-router-dom";
import {MdOutlinePictureAsPdf, MdOutlineChevronRight} from "react-icons/md";
import {useTranslation} from "react-i18next";

const PageListItem = ({subitems, name, to, isPdf = false}) => {
  const {i18n} = useTranslation()
  const language = i18n.language;
  if (isPdf) {
    return (
      <a className={'bg-item-bg flex items-center text-base md:text-xl font-normal text-gray-800 py-4 px-7 my-3 hover:bg-green hover:text-white'} href={to} target="_blank">
        {name}
        <MdOutlinePictureAsPdf className={'ml-auto'} size={25} />
      </a>
    )
  }

  return (
    <div>
      <Link className={'bg-item-bg flex items-center text-base md:text-xl font-normal text-gray-800 py-4 px-7 my-3 hover:bg-green hover:text-white'} to={to}>
        {name}
        <MdOutlineChevronRight className={'ml-auto'} size={25} />
      </Link>
      {subitems?.length > 0 && (
        <div className={'ml-10'}>
          {subitems.map((item) => (
            <PageListItem rounded key={item.id} subitems={item.children} name={item[`title_${language}`]} to={`/publications/vacancies/${item.id}`} />
          ))}
        </div>
      )}
    </div>
  );
}

export default PageListItem;
