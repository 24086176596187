import React, {useEffect, useMemo} from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Background from "@assets/images/background.png";
import API from "@utils/plugins/API";
import {useDispatch} from "react-redux";
import {overwriteData} from "@redux/general/Action";

const Layout = ({children, hasBackground = false}) => {
  const dispatch = useDispatch()
  const layoutStyle = useMemo(() => {
    return hasBackground ? {
      backgroundImage: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      backgroundSize: 'cover',
    } : {}
  }, [hasBackground])

  useEffect(() => {
    API.get('all-data').then((res) => {
      dispatch(overwriteData(res.data))
    })
  }, [])

  return (
    <div>
      <div className={"min-h-screen flex flex-row bg-white justify-center"}>
        <div className={"flex-1 flex flex-col items-center bg-background"} style={layoutStyle}>
          <Header/>
          <div style={{maxWidth: '1800px', width: '100%'}}>{children}</div>
          <Footer/>
        </div>
      </div>
    </div>

  );
};

export default Layout;
