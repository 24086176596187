import {OVERWRITE_GENERAL, RESET_GENERAL} from '@constants/redux';

const generalState = (state = {}, action) => {
    switch (action.type) {
        case OVERWRITE_GENERAL:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_GENERAL:
            return {};
        default:
            return state;
    }
};

export default generalState

