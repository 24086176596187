import React, {useState} from "react";
import Layout from "@hoc/layouts/Layout";
import WaterSupplyForm from "@components/ReportAbuse/WaterSupplyForm";
import WorkersForm from "@components/ReportAbuse/WorkersForm";

const items = [
  {
    title: 'Drejtoria Ekonomike dhe Shërbimeve Mbështetëse',
    email: 'Florian.Demi@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Monitorimit dhe e Mbikqyrjes',
    email: 'Arben.Kacorri@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Planifikimit të Investimeve',
    email: 'Alda.Yzeiraj@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Koordinimit, Analizës dhe Informacionit',
    email: 'Albana.Jani@akuk.gov.al',
  },
]

const ReportAbusePage = () => {
  const [activeTab, setActiveTab] = useState('waterSupply');

  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <div className={'h-13 bg-primary flex items-center text-xl font-semibold text-white px-7'}>
          Denonco
        </div>
        <div className={'h-auto flex flex-col-reverse md:flex-row'}>
          <div className={'flex-1 bg-white rounded-br-8xl sm:rounded-br-extreme text-white px-5 pr-10 lg:pr-20 pb-5'}>
            <div className={'mt-3 ml-4 mr-5 md:mr-24 lg:mr-36'}>
              <div className={'flex my-4 bg-gray-200 p-1.5 rounded-full mb-6'}>
                <div
                  onClick={() => setActiveTab('waterSupply')}
                  className={`cursor-pointer flex-1 text-center rounded-full py-1 ${activeTab === 'waterSupply' ? 'bg-primary text-white' : 'text-primary'}`}>
                  Denonco Anomali në Rrjetin e Ujësjellësit
                </div>
                <div
                  onClick={() => setActiveTab('workers')}
                  className={`cursor-pointer flex-1 text-center rounded-full py-1 ${activeTab === 'workers' ? 'bg-primary text-white' : 'text-primary'}`}>
                  Denonco Sjelljet Jo Etike të Punonjësve
                </div>
              </div>
              {activeTab === 'waterSupply' ? <WaterSupplyForm /> : <WorkersForm />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ReportAbusePage;
