const en = {
  "header": {
    "home": "Home",
    "aboutUs": "About us",
    "projects": "Projects",
    "publications": "Publications",
    "media": "Media",
    "services": "Services",
    "contacts": "Contacts",
    "report": "Report",
  },
  "aboutUs": {
    "legalFramework": "Legal framework",
    "legalFrameworkInfo": "AKUK is a government body specialized in water supply and sewerage, wastewater treatment and waste management, in accordance with the competences defined in this Decision. AKUK technically supports the implementation of policies and strategies in the field of water supply and sewerage, wastewater treatment, territorial planning",
    "mission": "Mission",
    "missionInfo": "The mission of the NAWS is to carry out specific state policies in the water supply and sewerage sector, treatment of waste waters, territorial planning",
    "budget": "Budget",
    "budgetInfo": "Information on the budget and financial data of AKUK",
    "structure": "Structure",
    "structureInfo": "The structure and organigram of the NAWS shall be approved by the Prime Minister order, according to the provisions of the legislation applicable to the organization and functioning of the state administration",
    "map": "Map",
    "mapInfo": "Coming Soon",
    "socialResponsibility": "Social responsibility",
    "socialResponsibilityInfo": "AKUK, in addition to the mission and services it offers, it considers Social Responsibility very important for sustainability in improving the conditions of society",
    "taskForce": "Task Force",
    "TaskForceInfo": "AKUK, in addition to the mission and services it offers, it considers Task Force very important for sustainability in improving the conditions of society",
  },
  "projects": {
    "financedByState": "Projects financed by the state budget",
    "financedByStateInfo": "Guides the technical and managerial aspects, and cooperates water supply and sewerage projects, WWTPs, territorial planning, financed by the state budget ",
    "financedByStateInfoShort": "Guides the technical and managerial aspects, and cooperates water supply and sewerage projects, financed by the state budget ",

    "financedByInternational": "Projects financed by the international financial institutions ",
    "financedByInternationalInfo": "Guides the technical and managerial aspects, and cooperates water supply and sewerage projects, WWTPs, territorial planning, financed by the international financial institutions ",
    "financedByInternationalInfoShort": "Guides the technical and managerial aspects, and cooperates water supply and sewerage projects, financed by the international financial institutions ",

    "implants": "Wastewater treatment plants ",
    "implantsInfo": "Prepare need assessments for investments in construction/rehabilitation/expansion of wastewater treatment plants ",
    "completed": "Completed projects",
    "completedInfo": "Completed projects financed by the state budget or other international financial institutions",
  },
  "publications": {
    "transparencyProgram": "Transparency program",
    "transparencyProgramInfo": "The transparency program specifies the categories of information that must be made public, the method of publication, as well as the legal provisions from which the obligation to publish arises",

    "registerOfRequestsAndResponses": "Register of requests and responses",
    "registerOfRequestsAndResponsesInfo": "Register of information requests",

    "theRightToInformation": "The right to information",
    "coordinatorForTheRightToInformation": "Coordinator for the right to information",
    "coordinatorForTheRightToInformationInfo": "",

    "tenders": "Tenders",
    "tendersInfo": "Information on AKUK tenders",

    "others": "Others",
    "othersInfo": "Other informations",

    "reports": "Reports",
    "reportsInfo": "Periodic reports related to AKUK and Water Supplies Companies",

    "vacancies": "Vacancies",
    "vacanciesInfo": "Information on AKUK vacancies",

    "expropriation": "Expropriation",
    "expropriationInfo": "Information about expropriation procedures",
  },
  "readMore": "Read more",
  "news": "News",
  "latestNews": "Latest news",
  "photoGallery": "Photo gallery",
  "videoGallery": "Video gallery",
  "photosFromVariousProjects": "Photos from various projects",
  "videosFromVariousProjects": "Videos from various projects",
  "contractor": "Contractor",
  "authority": "Authority",
  "value": "Value",
  "beneficiary": "Beneficiary",
  "population": "Population",
  "waterSupplier": "Water supplier",
  "partners": "Partners",
  "homeCards": {
    "waterUtilities": "Water <br/> Utilities",
    "transparency": "Transparency",
    "vacancies": "Vacancies",
    "theRightOfInformation": "The right <br/> of information",
  },
  "homeSlider": {
    "waterUtilities": "Water Utilities",
    "actualSites": "Actual Sites",
    "subscribers": "Water Supply Coverage",
  },
  "structure": {
    "generalDirector": "General Director",
    "generalDirectorInfo": "Mr. Klevis Jahaj completed his studies at the Faculty of Economics at the University of Tirana, Finance. Since October 2021, Mr. Jahaj was appointed as the General Director of the National Agency of Water Supply- Sewerage in Albania, an institution under the Ministry of Infrastructure and Energy.",

    "deputyDirector": "Deputy Director",
    "deputyDirectorInfo": "Mr. Flauers Shoshi graduated in civil engineering from the Polytechnic University of Tirana. Since 2019, Mr. Shoshi is the Deputy Director of the National Agency of Water Supply-Sewerage in Albania.",

    "directorateOfEconomicAndSupportServices": "Directorate of Economic and Support Services",
    "directorateOfEconomicAndSupportServicesInfo": "Mr. Florian Demi holds a Master of Science degree in Accounting and Auditing from the Faculty of Economy in Tirana. Since October 2021 Mr. Demi is the Economic Director of the National Agency of Water Supply-Sewerage in Albania.",

    "directorateOfMonitoringAndSupervision": "Directorate of Monitoring and Supervision",
    "directorateOfMonitoringAndSupervisionInfo": "Mr. Arben Kacorri graduated from the Faculty of Natural Sciences \"Mathematics-Physics\" University \"Eqerem Çabej\", Gjirokastër. Since 2019, he holds the position of Director of Monitoring and Supervision at the National Agency of Water Supply-Sewerage in Albania",

    "directorateOfInvestmentPlanning": "Directorate of Investment Planning",
    "directorateOfInvestmentPlanningInfo": "Mr. Jetlir Gjergji graduated in Architecture at the Polytechnic University of Tirana and Master in Spatial Planning and GIS at Polis University. From October 2023 Mr. Gjergji is the Director of Investment Planning at the National Water and Sewerage Agency of Albania.",

    "directorateOfCoordinationAnalysisAndInformation": "Directorate of Coordination, Analysis and Information",
    "directorateOfCoordinationAnalysisAndInformationInfo": "Ms. Albana Jani completed her studies at the Faculty of Economics at the University of Tirana, Economics. Since 2019, she holds the position of Director of Coordination, Analysis and Information at the National Agency of Water Supply-Sewerage in Albania",
  },
  "mr": "Mr. ",
  "ms": "Ms. ",
  "organization": "ORGANIZATION",
  "salaryCategory": "SALARY CATEGORY",
}
export default en
