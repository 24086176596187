import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import {ReactComponent as Cube} from "@assets/svg/cube.svg";
import {ReactComponent as DollarRotate} from "@assets/svg/dollar_rotate.svg";
import {ReactComponent as Webhook} from "@assets/svg/webhook.svg";
import {ReactComponent as Map} from "@assets/svg/map.svg";
import PageChildrenPreview from "@components/Pages/PageChildrenPreview";

const items = [
  {
    title: 'projects.financedByState',
    svg: <Cube />,
    description: 'projects.financedByStateInfo',
    url: 'projects/state',
  },
  {
    title: 'projects.financedByInternational',
    svg: <DollarRotate />,
    description: 'projects.financedByInternationalInfo',
    url: 'projects/international',
  },
  {
    title: 'projects.implants',
    svg: <Webhook />,
    description: 'projects.implantsInfo',
    url: 'projects/implants',
  },
  {
    title: 'projects.completed',
    svg: <Map />,
    description: 'projects.completedInfo',
    url: 'projects/finished',
  },
]


const ProjectsPage = () => {
  return (
    <Layout hasBackground>
      <div className={'my-10 w-full lg:w-4/5 xl:w-2/3 px-10 lg:px-0 mx-auto'}>
        <PageChildrenPreview items={items} />
      </div>
    </Layout>
  );
}

export default ProjectsPage;
