import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import {FaRegEnvelope} from "react-icons/fa";

const items = [
  {
    title: 'Drejtoria Ekonomike dhe Shërbimeve Mbështetëse',
    email: 'Florian.Demi@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Monitorimit dhe e Mbikqyrjes',
    email: 'Arben.Kacorri@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Planifikimit të Investimeve',
    email: 'Jetlir.Gjergji@akuk.gov.al',
  },
  {
    title: 'Drejtoria e Koordinimit, Analizës dhe Informacionit',
    email: 'Albana.Jani@akuk.gov.al',
  },
]

const Item = ({item}) => (
  <a className={`cursor-pointer flex-1 flex flex-col justify-between mt-3 p-3 rounded-xl duration-500 transition-all border text-primary bg-white shadow-md hover:border-0 hover:text-white hover:bg-green`} href={`mailto:${item.email}`} >
    <h1 className={'font-extrabold text-base lg:text-lg pr-20'}>{item.title}</h1>
    <div className={'flex items-center'}>
      <FaRegEnvelope />
      <p className={'underline font-light ml-2'}>{item.email}</p>
    </div>
  </a>
)

const ContactsPage = () => {
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <div className={'h-13 bg-primary flex items-center text-xl font-semibold text-white px-7'}>
          Kontakte
        </div>
        <div className={'h-auto flex flex-col-reverse md:flex-row'}>
          <div className={'flex-1 flex flex-col mr-3'}>
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
          <div className={'flex-1 bg-primary rounded-br-8xl sm:rounded-br-extreme text-white px-5 pr-10 lg:pr-20 pb-5'}>
            <h1 className={'text-lg md:text-xl lg:text-2xl font-bold'}>AKUK - Agjencia Kombëtare e Ujësjellës-Kanalizimeve</h1>
            <hr className={'w-1/5 my-6'}/>
            <p className={'text-lg font-thin my-3'}>Rruga Sami Frashëri <br/> Nr. 4, Tirane <br/> Shqiperi</p>
            <h1 className={'text-xl font-bold pt-10'}>Sekretaria</h1>
            <hr className={'w-1/5 my-6'}/>
            <p className={'text-lg font-thin my-3'}>Tel: +355 (0)42256091</p>
            <div className={'flex items-center font-thin'}>
              <FaRegEnvelope />
              <a href={'mailto:akum@akuk.gov.al'} className={'ml-2'}>info@akuk.gov.al</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactsPage;
