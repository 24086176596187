import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import {FaRegEnvelope} from "react-icons/fa";
import ArrowRight from "@assets/svg/arrow_right.svg";
import {useTranslation} from "react-i18next";

const data = {
  title: 'Halim Hamzallari',
  email: 'halim.hazamllari@akuk.gov.al',
}

const Item = ({item}) => (
  <a className={`cursor-pointer flex flex-col justify-between mt-3 px-3 py-5 rounded-xl duration-500 transition-all border text-primary bg-white shadow-md hover:border-0 hover:text-white hover:bg-green`} href={`mailto:${item.email}`} >
    <h1 className={'font-extrabold text-base lg:text-lg pr-20'}>{item.title}</h1>
    <div className={'flex items-center mt-4'}>
      <FaRegEnvelope />
      <p className={'underline font-light ml-2'}>{item.email}</p>
    </div>
  </a>
)

const InfoSectionPage = () => {
  const {t} = useTranslation()

  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <div className={'h-13 bg-primary flex items-center text-xl font-semibold text-white px-7'}>
          {t('publications.theRightToInformation')}
        </div>
        <div className={'h-auto flex flex-col md:flex-row'}>
          <div className={'flex-1 flex flex-col ml-2 mr-5 pb-3'}>
            <div className={'flex-1'}>
              <h1 className={'mt-5 mb-10 text-2xl font-bold text-primary'}>{t('publications.coordinatorForTheRightToInformation')}</h1>
              <div className="flex items-center">
                <img src={ArrowRight} alt=""/>
                <hr className={'flex-1 mx-5 border-primary'}/>
              </div>
            </div>
            <Item item={data} />
          </div>
          <div className={'flex-1 bg-primary rounded-br-8xl sm:rounded-br-extreme text-white px-5 pr-10 lg:pr-20 pb-5'}>
            <p className={'text-lg font-thin my-3'}>Rruga Sami Frashëri <br/> Nr. 4, Tirane <br/> Shqiperi</p>
            <p className={'text-lg font-thin my-8'}>Tel: 04 256 091</p>
            <p className={'text-lg font-thin my-3'}>Orari E hene- E Enjte  08:00-16:30</p>
            <p className={'text-lg font-thin my-3'}>E Premte  08:00-14:00</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InfoSectionPage;
