import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Logo from "@assets/svg/logo.svg";
import {FaBars} from "react-icons/fa";
import {menuItems} from "@constants/menuItems";
import MobileMenu from "@hoc/partials/MobileMenu";
import HeaderItem from "@hoc/partials/HeaderItem";
import {Link} from "react-router-dom";

const ChangeLanguage = () => {
  const {i18n} = useTranslation();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang).then(() => localStorage.setItem('lang', lang));
  }

  const generateItem = (label) => {
    const isActive = i18n.language === label
    return (
      <div className={`mx-2 ${isActive ? 'font-extrabold' : 'font-thin' }`} onClick={() => changeLang(label)}>
        {label?.toUpperCase()}
      </div>
    )
  }

  return (
    <div>
      <div className={'cursor-pointer flex items-center text-white px-3'}>
        {generateItem('al')} /
        {generateItem('en')}
      </div>
    </div>
  )
}

const Header = () => {
  const {t} = useTranslation();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setOpenMobileMenu((prev) => !prev)
  }

  return (
    <header className="bg-primary text-sm lg:text-base w-full flex justify-center">
      <div className={'flex p-5 items-center'} style={{maxWidth: '1800px', width: '100%'}}>
        <Link to={'/'}>
          <img src={Logo} className={'h-8 lg:h-12'}/>
        </Link>
        <div className={'flex-1 hidden md-lg:flex items-center'}>
          <div className={'flex ml-10 px-5 py-2 flex-1 rounded-2xl shadow-inner'}>
            {menuItems.map((item, index) => (
              <HeaderItem key={index} item={item} />
            ))}
          </div>
          <ChangeLanguage />
        </div>
        <div
          onClick={toggleMobileMenu}
          className={`text-2xl cursor-pointer md-lg:hidden text-white ml-auto`}>
          <FaBars />
        </div>
      </div>
      <MobileMenu open={openMobileMenu} onClose={toggleMobileMenu} />
    </header>
  )
}

export default Header;

