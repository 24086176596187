import React from 'react';
import {Link} from "react-router-dom";
import {FaCircleNotch} from "react-icons/fa";

const DefaultButton = (
  {
    label,
    link,
    to,
    onClick,
    bgColor,
    bgColorHover,
    textColor,
    xs = false,
    sm = false,
    loading = false,
    ...props
  }) => {
  let sizingClasses = 'py-2 px-6 text-md lg:text-lg'
  if (xs) {
    sizingClasses = 'py-1 px-3 text-xs'
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs'
  }

  const className = `group relative flex justify-center items-center border border-transparent font-bold rounded-full ${textColor ? textColor : 'text-white'} ${sizingClasses} ${bgColor ? bgColor : 'bg-primary'}  ${bgColorHover ? bgColorHover : 'hover:bg-primary-700'} focus:outline-none disabled:bg-gray-400`

  if (link) {
    return  (
      <Link
        to={to}
        className={className}
        {...props}
      >
        {label}
      </Link>
    )
  }

  return (
    <button
      disabled={loading}
      onClick={loading ? null : onClick}
      className={className}
      {...props}
    >
      {loading && <FaCircleNotch size={20} className={'animate-spin-slow mr-2'} />}
      {label}
    </button>
  )
}

export default DefaultButton;

