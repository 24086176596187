import React, {useMemo} from "react";
import SlideShow from "@core/slideshow/SlideShow";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from "lodash";
import {getMediaUrl} from "@utils/helpers";

const HomeBanner = () => {
  const {t} = useTranslation()
  const data = useSelector((state) =>
    _.get(state, "generalState.homeSliders", [])
  );

  const sliderData = useMemo(() => {
    return data.map((item) => getMediaUrl(item))
  }, [data])

  return (
    <div className={'relative'}>
      <SlideShow images={sliderData} />
      <div className={'absolute flex flex-col bottom-10 md:bottom-18 lg:bottom-20 xl:bottom-24 left-0 md:left-5 w-full md:w-5/6 px-5 md:px-10 lg:mb-0'}>
      </div>
    </div>
  );
}

export default HomeBanner;
