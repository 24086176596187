import React from "react";

const PageHeader = ({title}) => {
  return (
    <div className={'bg-primary flex rounded-tl-full justify-end items-center text-base md:text-xl font-semibold text-white py-4 pl-20 pr-7'}>
      {title}
    </div>
  );
}

export default PageHeader;
