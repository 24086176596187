import React from 'react';
import { Switch, BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import HomePage from "@pages/HomePage";
import AboutUsPage from "@pages/AboutUs/AboutUsPage";
import ProjectsPage from "@pages/Projects/ProjectsPage";
import MediaPage from "@pages/MediaPage";
import ContactsPage from "@pages/ContactsPage";
import UnderConstructionPage from "@pages/UnderConstructionPage";
import StateProjectsPage from "@pages/Projects/StateProjectsPage";
import InternationalProjectsPage from "@pages/Projects/InternationalProjectsPage";
import ImplantsProjectsPage from "@pages/Projects/ImplantsProjectsPage";
import FinishedProjectsPage from "@pages/Projects/FinishedProjectsPage";
import LegalFrameworkPage from "@pages/AboutUs/LegalFrameworkPage";
import StructurePage from "@pages/AboutUs/StructurePage";
import TransparencyProgramPage from "@pages/Publications/TransparencyProgram/TransparencyProgramPage";
import RequestRegisterPage from "@pages/Publications/RequestRegister/RequestRegisterPage";
import ShowProjectPage from "@pages/Projects/ShowProjectPage";
import BudgetPage from "@pages/AboutUs/BudgetPage";
import MissionPage from "@pages/AboutUs/MissionPage";
import PublicationsPage from "@pages/Publications/PublicationsPage";
import SocialResponsibilityPage from "@pages/AboutUs/SocialResponsibilityPage";
import ShowSocialResponsibilityPage from "@pages/AboutUs/ShowSocialResponsibilityPage";
import OtherPublicationsPage from "@pages/Publications/Other/OtherPublicationsPage";
import ShowOtherPublicationPage from "@pages/Publications/Other/ShowOtherPublicationPage";
import VacanciesPage from "@pages/Publications/Vacancy/VacanciesPage";
import ShowVacancyPage from "@pages/Publications/Vacancy/ShowVacancyPage";
import ReportsPage from "@pages/Publications/Report/ReportsPage";
import ShowReportPage from "@pages/Publications/Report/ShowReportPage";
import ShowTenderPage from "@pages/Publications/Tender/ShowTenderPage";
import TendersPage from "@pages/Publications/Tender/TendersPage";
import ExpropriationPage from "@pages/Publications/Expropriation/ExpropriationPage";
import ShowExpropriationPage from "@pages/Publications/Expropriation/ShowExpropriationPage";
import ShowNewsPage from "@pages/ShowNews";
import InfoSectionPage from "@pages/Publications/Info/InfoSectionPage";
import ShowTransparencyProgramPage from "@pages/Publications/TransparencyProgram/ShowTransaprencyProgramPage";
import ReportAbusePage from "@pages/ReportAbusePage";
import TaskForcePage from "@pages/AboutUs/TaskForcePage";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={HomePage}
        />
        <Route
          exact
          path="/about-us"
          component={AboutUsPage}
        />
        <Route
          exact
          path="/about-us/legal-framework"
          component={LegalFrameworkPage}
        />
        <Route
          exact
          path="/about-us/mission"
          component={MissionPage}
        />
        <Route
          exact
          path="/about-us/budget"
          component={BudgetPage}
        />
        <Route
          exact
          path="/about-us/map"
          component={UnderConstructionPage}
        />
        <Route
          exact
          path="/about-us/structure"
          component={StructurePage}
        />
        <Route
          exact
          path="/about-us/task-force"
          component={TaskForcePage}
        />
        <Route
          exact
          path="/projects"
          component={ProjectsPage}
        />
        <Route
          exact
          path="/projects/state"
          component={StateProjectsPage}
        />
        <Route
          exact
          path="/projects/international"
          component={InternationalProjectsPage}
        />
        <Route
          exact
          path="/projects/implants"
          component={ImplantsProjectsPage}
        />
        <Route
          exact
          path="/projects/finished"
          component={FinishedProjectsPage}
        />
        <Route
          exact
          path="/projects/:id"
          component={ShowProjectPage}
        />
        <Route
          exact
          path="/publications"
          component={PublicationsPage}
        />
        <Route
          exact
          path="/publications/transparency-program"
          component={TransparencyProgramPage}
        />
        <Route
          exact
          path="/publications/transparency-program/show"
          component={ShowTransparencyProgramPage}
        />
        <Route
          exact
          path="/publications/request-register"
          component={RequestRegisterPage}
        />
        <Route
          exact
          path="/publications/info"
          component={InfoSectionPage}
        />
        <Route
          exact
          path="/publications/expropriation"
          component={ExpropriationPage}
        />
        <Route
          exact
          path="/publications/expropriation/:id"
          component={ShowExpropriationPage}
        />
        <Route
          exact
          path="/publications/tenders"
          component={TendersPage}
        />
        <Route
          exact
          path="/publications/tenders/:id"
          component={ShowTenderPage}
        />
        <Route
          exact
          path="/publications/vacancies"
          component={VacanciesPage}
        />
        <Route
          exact
          path="/publications/vacancies/:id"
          component={ShowVacancyPage}
        />
        <Route
          exact
          path="/publications/other"
          component={OtherPublicationsPage}
        />
        <Route
          exact
          path="/publications/other/:id"
          component={ShowOtherPublicationPage}
        />
        <Route
          exact
          path="/publications/reports"
          component={ReportsPage}
        />
        <Route
          exact
          path="/publications/reports/:id"
          component={ShowReportPage}
        />
        <Route
          exact
          path="/media"
          component={MediaPage}
        />
        <Route
          exact
          path="/media/news/:id"
          component={ShowNewsPage}
        />
        <Route
          exact
          path="/services"
          component={UnderConstructionPage}
        />
        <Route
          exact
          path="/contacts"
          component={ContactsPage}
        />
        <Route
          exact
          path="/denonco"
          component={ReportAbusePage}
        />
        <Redirect from='/' to='/'/>
      </Switch>
    </Router>
  );
};

export default Routes;
