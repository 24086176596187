import React, {useEffect, useState} from "react";
import DownArrow from '@assets/svg/down_arrow.svg';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from "lodash";
import {getMediaUrl} from "@utils/helpers";

const ImageItem = ({isActive, image, onClick}) => (
  <div className={'cursor-pointer shadow-md rounded-lg'} onClick={() => onClick(image)}>
    <div className="relative">
      {isActive ? (
        <a className="absolute inset-0 z-10 bg-green text-center flex flex-col items-center justify-center opacity-100 bg-opacity-60 rounded-lg">
          <h1 className="tracking-wider text-white">Active Image</h1>
        </a>
      ) : (
        <a className="absolute inset-0 z-10 bg-black text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-50 duration-300 rounded-lg">
          <h1 className="tracking-wider text-white">Open Image</h1>
        </a>
      )}
      <a href="#" className="relative">
        <div className="flex flex-wrap content-center">
          <img src={getMediaUrl(image)} className="w-100 h-50 rounded-lg object-cover" alt="" />
        </div>
      </a>
    </div>
  </div>
)


const MediaImage = () => {
  const {t, i18n} = useTranslation()
  const language = i18n.language;
  const images = useSelector((state) =>
    _.get(state, "generalState.mediaImages", [])
  );

  const [activeImage, setActiveImage] = useState({})


  const handleImageChange = (image) => {
    setActiveImage(image)
    const element = document.getElementById("main-image");
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  useEffect(() => {
    setActiveImage(images[0] || {})
  }, [images])

  return (
    <div>
      <img id={'main-image'} className={'w-full rounded-br-8xl sm:rounded-br-extreme max-h-150 object-cover shadow-md'} src={getMediaUrl(activeImage)}/>
      <div className={'flex flex-col sm:flex-row my-8'}>
        <h1 className={'mr-10 text-xl font-extrabold w-30 mb-2 sm:mb-0'}>TITULLI</h1>
        <p className={'flex-1 font-thin'}>
          {activeImage[`title_${language}`]}
        </p>
      </div>
      <hr className={'w-1/2 sm:w-1/3 border-gray-600 my-5'}/>
      <div className={'flex items-center mb-5 sm:mb-8 md:mb-10'}>
        <div className="w-10 sm:w-20 md:w-30 flex justify-center">
          <img className={'w-5 sm:w-8'} src={DownArrow} />
        </div>
        <p className={'flex-1 font-thin ml-5 sm:ml-10 flex-1 '}>{t('photosFromVariousProjects')}</p>
      </div>
      <div className={'grid grid-cols-2 md:grid-cols-4 gap-5 flex flex-wrap'}>
        {images.map((image) => (
          <ImageItem isActive={image.id === activeImage.id} image={image} onClick={handleImageChange} />
        ))}
      </div>
    </div>
  );
}

export default MediaImage;
