import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import MediaVideo from "@components/Media/MediaVideo";
import MediaImage from "@components/Media/MediaImage";
import MediaNews from "@components/Media/MediaNews";
import {useTranslation} from "react-i18next";

const videos = [
  {
    title: 'Agjencia Kombëtare e Ujësjellës-Kanalizime është i vetmi organ shtetëror i specializuar në fushën e furnizimit general ujë të pijshëm, kanalizimeve të ujërave të zëza dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'SThccWxY184',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'lJ3eVCwyRVs',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'KOeR63OtN2Y',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'Q_Y0iXcSc1Q',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'lNbIZp7MitQ',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'qNZIvewDa9A',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: '4UZrsTqkcW4',
  },
  {
    title: 'Lorem ipsum dhe të bardha si dhe trajtimit të tyre. Ujësjellës Kanalizime dhe Infrastrukturës ujë të pijshëm, kanalizimeve të ujërave',
    videoId: 'GiUDWx9NpMU',
  },
]

const MediaPage = () => {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState('news')
  return (
    <Layout>
      <div className={'my-5 lg:my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <div className={'h-15 bg-primary flex justify-between items-center text-xl text-white px-10 mb-4 lg:mb-5'}>
          <div className={`cursor-pointer ${activeTab === 'news' ? 'font-extrabold' : 'font-light'}`} onClick={() => setActiveTab('news')}>{t('news')}</div>
          <div className={`cursor-pointer ${activeTab === 'image' ? 'font-extrabold' : 'font-light'}`} onClick={() => setActiveTab('image')}>{t('photoGallery')}</div>
          <div className={`cursor-pointer ${activeTab === 'video' ? 'font-extrabold ' : 'font-light'}`} onClick={() => setActiveTab('video')}>{t('videoGallery')}</div>
        </div>
        {activeTab === 'news' && <MediaNews />}
        {activeTab === 'image' && <MediaImage />}
        {activeTab === 'video' && <MediaVideo />}
      </div>
    </Layout>
  );
}

export default MediaPage;
