import React from 'react';
import uniqid from 'uniqid';

const FileInput = ({
  title = '',
  subtitle = '',
  fileName,
  handleFileSelect,
  error,
  removeError,
}) => {
  const id = uniqid();

  return (
    <div>
      <div className={`w-full text-primary text-sm ml-3 ${error && 'text-red-400'}`}>{title}</div>
      <div className="my-6">
        <label
          htmlFor={id}
          className={`${error ? 'border-red-400 text-red-400' : 'border-brand-light-blue text-brand-light-blue'}
                   font-normal focus:outline-none py-2 px-10 border 
                   active:opacity-60 rounded-6xl text-lg cursor-pointer text-primary w-full`}
        >
          Ngarko... <span className="text-xs text-brand-gray ml-5">{fileName}</span>
        </label>
        <input
          className="hidden"
          id={id}
          type="file"
          accept="image/*,video/mp4,video/x-m4v,video/*"
          onChange={(event) => {
            removeError && removeError();
            handleFileSelect(event.target.files[0]);
          }}
        />
      </div>
    </div>
  );
};

export default FileInput;
