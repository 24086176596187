import {OVERWRITE_APP_DATA, RESET_APP_DATA} from '@constants/redux';

export const appData = (payload) => {
    return {
        type: OVERWRITE_APP_DATA,
        payload,
    };
};

export const resetData = () => {
    return {
        type: RESET_APP_DATA,
    };
};
