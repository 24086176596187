
// GENERAL
export const OVERWRITE_GENERAL = 'OVERWRITE_GENERAL';
export const RESET_GENERAL = 'RESET_GENERAL';

// APP_DATA
export const OVERWRITE_APP_DATA = 'OVERWRITE_APP_DATA';
export const RESET_APP_DATA = 'RESET_APP_DATA';

// SPINNER
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

