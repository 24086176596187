import React from "react";
import Layout from "@hoc/layouts/Layout";
import PageHeader from "@components/Pages/PageHeader";
import ArrowRight from "@assets/svg/arrow_right.svg";
import {useParams} from "react-router";
import useSingleModule from "../hooks/useSingleModule";
import useTranslatedField from "../hooks/useTranslatedField";
import {getMediaUrl} from "@utils/helpers";

const ShowNewsPage = () => {
  const {id} = useParams()
  const item = useSingleModule('news', id)
  const {getLabel} = useTranslatedField(item)
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/6 px-10 lg:px-0 mx-auto'}>
        <PageHeader title={getLabel('title')} />
        <img className={'max-h-124 w-full object-cover my-4 rounded-2xl shadow-md'} src={getMediaUrl(item)} />
        <div className={'bg-white shadow-md px-10 sm:px-5 pt-5 pb-14 rounded-t-2xl'}>
          <hr className={'w-1/4 my-5 hidden sm:block border-gray-700'}/>
          <div className={'flex flex-col sm:flex-row pt-3 '}>
            <img src={ArrowRight} className={'w-8 h-8 mx-auto mb-5 sm:mb-0 sm:mx-10 rotate-90 sm:rotate-0'}/>
            <p className={'flex-1 text-justify sm:text-left font-light mx-auto sm:mx-0'}>
              {getLabel('body')}
            </p>
          </div>
        </div>
        <div className={'h-15 bg-primary rounded-br-full'}/>
      </div>
    </Layout>
  );
}

export default ShowNewsPage;
