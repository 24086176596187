import React from 'react';

const TextArea = (props) => {
    const { id, label, placeholder, onChange, error, onFocus, value } = props;
    return (
        <div>
            <label
              className={`w-full text-primary text-sm ml-3`}
              htmlFor={id}>
              {label}
            </label>
            <textarea
                id={id}
                autoComplete="off"
                className={`caret-brand-light-blue placeholder-gray-400 rounded-lg
                    px-5 py-2 appearance-none w-full 
                    text-gray-800 bg-gray-100
                    focus:outline-none focus:ring-primary-500 focus:border-indigo-500 focus:z-10 text-lg
                    ${error && 'border-red-400'}
                    `}
                rows="4"
                onChange={e => onChange(e.target.value)}
                onClick={onFocus}
                value={value}
                placeholder={placeholder} aria-label={label}/>
          <div className={`text-xs text-red-400 pt-2 ${error ? '' : 'pb-4'}`}>{error ? error.message : ''}</div>
        </div>
    )
}

export default TextArea;

