import React from "react";
import {useTranslation} from "react-i18next";
import City from "@assets/svg/documents.svg";
import {Link} from "react-router-dom";

const HomeBudget = () => {
  const {t} = useTranslation();
  return (
    <div
      className="3xl:max-w-7xl 3xl:mx-auto max-w-fit flex flex-col sm:flex-row items-center sm:justify-start xl:mx-30 lg:mx-5 lg:px-0 bg-primary rounded-3xl py-10 mb-20 mx-3 shadow-lg">
      <div className="lg:pl-30 sm:pl-10 pt-3 justify-center">
        <img style={{maxWidth: 180}} className="xl:w-auto lg:w-70 xs:w-81 w-24" src={City} alt="vector"/>
      </div>
      <div className="flex flex-col sm:pl-30 px-3 flex-wrap mt-10 sm:mt-0 items-center sm:items-start">
        <div className="xl:text-4xl font-black lg:text-2xl mm:text-xl text-white">
          {t('aboutUs.budget')}
        </div>
        <div
          className="xl:text-lg lg:text-md sm:px-0 xs:px-10 px-0 font-thin py-3 text-white">
          {t('aboutUs.budgetInfo')}
        </div>
        <Link to={'about-us/budget'} className={'px-5 py-3 mt-2 bg-white text-primary hover:bg-green hover:text-white duration-500 transition-all rounded-tr-full rounded-br-full rounded-bl-full'}>
          {t('readMore')}
        </Link>
      </div>
    </div>
  );
}

export default HomeBudget;
