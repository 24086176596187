import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

const SlideShow = ({images, showArrows = true}) => {
  return (
    <div className={'relative'}>
      <Carousel autoPlay interval={5000} showArrows={showArrows} showThumbs={false} showStatus={false} infiniteLoop scrollable={true}
                 showIndicators
                 renderIndicator={(onClickHandler, isSelected, index, label) => {
                   return (
                     <div
                       className="inline-flex flex-1 justify-center items-center md:mb-5"
                       onClick={onClickHandler}
                       onKeyDown={onClickHandler}
                       key={index}
                       role="button"
                       tabIndex={0}
                       aria-label={`${label} ${index + 1}`}
                     >
                       <div
                         className={`rounded-full z-20 cursor-pointer p-1  sm:p-1.5 mr-2 border-2  border-white ${isSelected ? 'bg-white' : ''}`}/>
                     </div>
                   );
                 }}
      >
        {images?.map((image) => (
          <div className={'relative'}>
            <img
              src={image}
              className="object-cover inset-0 transform ease-in h-100 md:h-141 lg:150 4xl:192"
              alt="user"
            />
            <div className="absolute inset-0 z-10 bg-black text-center bg-opacity-30" />
          </div>
        ))}
      </Carousel>
    </div>
  )
}
export default SlideShow;
