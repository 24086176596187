import {useTranslation} from "react-i18next";
import {useCallback} from "react";

const useTranslatedField = (item) => {
  const {i18n} = useTranslation()
  const language = i18n.language;

  const getLabel = useCallback((fieldName) => {
    return item[`${fieldName}_${language}`]
  }, [language, item])

  return {getLabel}
};

export default useTranslatedField;
