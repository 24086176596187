import React from "react";
import Layout from "@hoc/layouts/Layout";
import Lottie from "@core/Lottie";
import WaterLoading from "@assets/animations/water_loading.json";

const UnderConstructionPage = () => {
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 mx-auto flex flex-col items-center'}>
        <Lottie animationData={WaterLoading} width={'50%'} />
        <h1 className={'text-2xl text-center mt-10 font-semibold text-primary-600'}>
          Sorry this page is currently <br/> under construction
        </h1>
      </div>
    </Layout>
  );
}

export default UnderConstructionPage;
