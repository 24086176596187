import React, {useEffect, useState} from "react";
import CenterCrop from "@assets/svg/backgrounds/centerCrop.svg";
import Water from "@assets/svg/backgrounds/water.svg";
import Document from "@assets/svg/backgrounds/document.svg";
import User from "@assets/svg/backgrounds/user.svg";
import Info from "@assets/svg/backgrounds/information.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CardItem = ({leftAligned = false, image, url, children}) => {
  return (
    <Link to={url} className={`w-full flex-0 sm:flex-1 h-30 lg:h-44 flex flex-col justify-center items-start border pr-0 sm:items-center bg-primary hover:bg-green mx-0 sm:mx-1 my-1 cursor-pointer duration-500 rounded-lg ${leftAligned ? 'hover:-translate-x-2' : 'hover:translate-x-2'}`}
         style={{
           backgroundImage: `url(${image})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: '50% 80%',
           backgroundPosition: leftAligned ? 'left' :'right'
         }}
    >
      <h1 className={'text-white text-2xl lg:text-4xl font-extrabold text-right sm:text-left w-full'}>
        <div className={`${leftAligned ? 'ml-32 md:ml-50' : 'ml-18 md:ml-30'}`}>
          <h1 className={'text-left'}>{children}</h1>
        </div>
      </h1>
    </Link>
  )
}

const HomeCards = () => {
  const {t} = useTranslation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className={'py-10 px-5 md:px-8 lg:px-24 xl:px-40 relative mt-5 sm:mt-15'}>
      <div className="flex flex-col sm:flex-row">
        <CardItem url={'/services'} leftAligned image={Water}>
         <div dangerouslySetInnerHTML={{__html: t('homeCards.waterUtilities')}} />
        </CardItem>
        <CardItem url={'/publications/transparency-program'} leftAligned={innerWidth < 640} image={Document}>
          <div dangerouslySetInnerHTML={{__html: t('homeCards.transparency')}} />
        </CardItem>
      </div>
      <div className={'lg:absolute lg:bottom-0 lg:h-full lg:flex lg:items-center lg:w-40 hidden lg:display-block'} style={{left: 'calc(50% - 5.1rem)', zIndex: 999}}>
        <img src={CenterCrop} />
      </div>
      <div className={'flex flex-col sm:flex-row'}>
        <CardItem url={'/publications/vacancies'} leftAligned image={User}>
          <div dangerouslySetInnerHTML={{__html: t('homeCards.vacancies')}} />
        </CardItem>
        <CardItem url={'/publications/info'} leftAligned={innerWidth < 640} image={Info}>
          <div dangerouslySetInnerHTML={{__html: t('homeCards.theRightOfInformation')}} />
        </CardItem>
      </div>
    </div>
  )
}

export default HomeCards;
