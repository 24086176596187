import React from 'react';
import Twitter from "@assets/svg/socials/twitter.svg";
import Facebook from "@assets/svg/socials/facebook.svg";
import Email from "@assets/svg/socials/email.svg";
import Instagram from "@assets/svg/socials/instagram.svg";
import Logo from "@assets/svg/logo.svg";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-primary w-full flex flex-col justify-between items-center pt-4 sm:pt-10 pb-10 px-5 sm:px-10 lg:px-20 md:flex-row mt-auto">
      <img src={Logo} className={'h-10 mb-10 mt-10'}/>
      <div className={'text-white text-center md:text-left mb-10 md:mb-0'}>
        <a href="mailto:unedenoncoj@akuk.gov.al" className={'py-1 hover:underline'}>Denonco Korrupsionin</a>
        <div className={'py-1'}>
          <Link to={'/about-us/legal-framework'} className={'hover:underline'}>Legjislacioni</Link>
        </div>
        <div className={'py-1'}>
          <Link to={'/publications/reports'} className={'hover:underline'}>Benchmarking</Link>
        </div>
        <div className={'py-1'}>
          <a href="https://www.infrastruktura.gov.al/rrjeti-ujesjelleskanalizime/" target="_blank" className={'py-1 hover:underline'}>Ministria e Infrastrukturës dhe Energjisë</a>
        </div>
        <div className={'py-1'}>
          <a href="https://www.shuk.al/" target="_blank" className={'py-1 hover:underline'}>Shërbimi i Ujësjellës Kanalizimeve</a>
        </div>
      </div>
      <div className={'text-white w-1/2 sm:w-1/4 flex flex-col justify-end'}>
        <div className={'flex justify-evenly md:justify-end items-center pb-5'}>
          <a href="https://twitter.com/akum_al" target="_blank" className={'opacity-60 hover:opacity-100'}>
            <img src={Twitter} className={'w-6 h-6 mx-0 md:mx-2'}/>
          </a>
          <a href="https://www.facebook.com/AKUMalb/" target="_blank" className={'opacity-60 hover:opacity-100'}>
            <img src={Facebook} className={'w-6 h-6 mx-0 md:mx-2'}/>
          </a>
          <a href="" className={'opacity-60 hover:opacity-100'}>
            <img src={Email} className={'w-6 h-6 mx-0 md:mx-2'}/>
          </a>
          <a href="https://www.instagram.com/akum.alb/" target="_blank" className={'opacity-60 hover:opacity-100'}>
            <img src={Instagram} className={'w-6 h-6 ml-0 md:ml-2'}/>
          </a>
        </div>
        <p className={'font-extralight text-sm lg:text-base text-center md:text-right'}>
          Agjencia Kombëtare e Ujësjellës Kanalizimeve <br/>Rruga Sami Frasheri, Nr. 4, Tirane +355 42 256091
        </p>
      </div>
    </footer>
  )
}

export default Footer;

