import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from "lodash";
import {getMediaUrl} from "@utils/helpers";
import useTranslatedField from "../../hooks/useTranslatedField";
import ClampLines from 'react-clamp-lines';

const Item = ({item}) => {
  const {t} = useTranslation()
  const {getLabel} = useTranslatedField(item)

  return (
    <div className={'flex flex-col'}>
      <div className={'w-full h-62'}>
        <img src={getMediaUrl(item)} className={'h-full w-full object-cover rounded-tl-2xl rounded-tr-2xl'}/>
      </div>
      <div className={'flex flex-col justify-between h-62 bg-white shadow-md rounded-br-2xl rounded-bl-2xl pt-3 pb-4 lg:pb-5 px-3 lg:px-5'} style={{marginTop: '-10px'}}>
        <div>
          <ClampLines
            id={`title-${item.id}`}
            text={getLabel('title')}
            lines={2}
            ellipsis="..."
            className="text-base xl:text-lg font-extrabold text-gray-700"
            innerElement="p"
            buttons={false}
          />
        </div>
        <ClampLines
          id={`body -${item.id}`}
          text={getLabel('body')}
          lines={4}
          ellipsis="..."
          className="text-sm font-light text-gray-500 mb-5"
          innerElement="p"
          buttons={false}
        />
        <div>
          <Link to={`/media/news/${item.id}`} className={'bg-item-bg hover:bg-green text-gray-700 hover:text-white duration-500 p-2 px-5 text-sm rounded-bl-full rounded-r-full'}>
            {t('readMore')}
          </Link>
        </div>
      </div>
    </div>
  )
}

const HomeLatestNews = () => {
  const data = useSelector((state) =>
    _.get(state, "generalState.news", [])
  );
  const currentTableData = useMemo(() => {
    return data.slice(0, 4);
  }, [data]);

  return (
    <div>
      <h1 className={'text-3xl md:text-4xl font-bold text-center text-primary font-Roboto'}>Lajmet e fundit</h1>
      <div className={'grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-5 flex lg:flex-wrap lg:flex-row flex-col mt-10 mx-3 lg:mx-4  px-5 lg:px-0'}>
        {currentTableData.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default HomeLatestNews;
