import React from "react";
import Layout from "@hoc/layouts/Layout";
import DetailsPageWithFiles from "@components/Pages/DetailsPageWithFiles";
import {useParams} from "react-router";
import useSingleModule from "../../../hooks/useSingleModule";

const ShowReportPage = () => {
  const {id} = useParams()
  const item = useSingleModule('reports', id)
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/6 px-10 lg:px-0 mx-auto'}>
        <DetailsPageWithFiles item={item} />
      </div>
    </Layout>
  );
}

export default ShowReportPage;
