import React, {useEffect, useState} from "react";
import YouTube from 'react-youtube';
import DownArrow from '@assets/svg/down_arrow.svg';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from "lodash";

const VideoItem = ({isActive, video, onClick}) => (
  <div className={'cursor-pointer'} onClick={() => onClick(video)}>
    <div className="relative">
      {isActive ? (
        <a className="absolute inset-0 z-10 bg-green text-center flex flex-col items-center justify-center opacity-100 bg-opacity-60">
          <h1 className="tracking-wider text-white">Active Video</h1>
        </a>
      ) : (
        <a className="absolute inset-0 z-10 bg-black text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-50 duration-300">
          <h1 className="tracking-wider text-white">Watch Now</h1>
        </a>
      )}
      <a href="#" className="relative">
        <div className="flex flex-wrap content-center">
          <img src={`https://img.youtube.com/vi/${video.video}/mqdefault.jpg`} className="mx-auto  " alt="" />
        </div>
      </a>
    </div>
  </div>
)


const MediaVideo = () => {
  const {t, i18n} = useTranslation()
  const language = i18n.language;
  const videos = useSelector((state) =>
    _.get(state, "generalState.mediaVideos", [])
  );
  const [activeVideo, setActiveVideo] = useState({})
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const opts  = {
    height: innerWidth > 640 ? '500': '300',
    width: '100%',
    playerVars: {
      autoplay: 1,
      disablekb: 1,
      fs: 0,
      iv_load_policy: 3,
      listType: 'user_uploads',
      loop: 1,
      modestbranding: 1,
    },
  };

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  }

  const handleVideoChange = (video) => {
    setActiveVideo(video)
    const element = document.getElementById("main-video");
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  useEffect(() => {
    setActiveVideo(videos[0] || {})
  }, [videos])

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div id={'main-video'}>
        {activeVideo.video && <YouTube videoId={activeVideo.video} opts={opts} onReady={onPlayerReady} />}
      </div>
      <div className={'flex flex-col sm:flex-row my-8'}>
        <h1 className={'mr-10 text-xl font-extrabold w-30 mb-2 sm:mb-0'}>TITULLI</h1>
        <p className={'flex-1 font-thin'}>
          {activeVideo[`title_${language}`]}
        </p>
      </div>
      <hr className={'w-1/2 sm:w-1/3 border-gray-600 my-5'}/>
      <div className={'flex items-center mb-5 sm:mb-8 md:mb-10'}>
        <div className="w-10 sm:w-20 md:w-30 flex justify-center">
          <img className={'w-5 sm:w-7'} src={DownArrow} />
        </div>
        <p className={'flex-1 font-thin ml-10 flex-1 '}>{t('videosFromVariousProjects')}</p>
      </div>
      <div className={'grid grid-cols-2 md:grid-cols-4 gap-5 flex flex-wrap'}>
        {videos.map((video) => (
          <VideoItem isActive={video.id === activeVideo.id} video={video} onClick={handleVideoChange} />
        ))}
      </div>
    </div>
  );
}

export default MediaVideo;
