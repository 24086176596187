import React  from 'react';
import {menuItems} from "@constants/menuItems";
import useActiveMenuItem from "../../hooks/useActiveMenuItem";
import {Link} from "react-router-dom";
import {FaTimes} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const Item = ({text, url}) => {
  const {t} = useTranslation();
  const isActive = useActiveMenuItem(url, [], true)
  return (
    <li className={'border-b text-center py-3'}>
      <Link to={url} className={`text-lg text-primary cursor-pointer ${isActive && 'font-extrabold'}`}>
        {t(`header.${text}`)}
      </Link>
    </li>
  )
}

const MobileMenu = ({ open, onClose }) => {
  const {i18n} = useTranslation();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang).then(() => localStorage.setItem('lang', lang));
  }

  const generateItem = (label) => {
    const isActive = i18n.language === label
    return (
      <div className={`mx-2 text-lg ${isActive ? 'font-extrabold' : 'font-thin' }`} onClick={() => changeLang(label)}>
        {label?.toUpperCase()}
      </div>
    )
  }

  return (
    <aside
      className={`fixed overflow-hidden z-50 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out  ${
        open
          ? 'transition-opacity opacity-100 duration-500'
          : 'transition-opacity opacity-0 delay-500 translate-x-full'
      }`}>
      <div
        className={`w-52 sm:w-64 right-0 absolute bg-white bg-fixed h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform ${
          open ? 'translate-x-0 ' : 'translate-x-full'
        }`}>
        <article className="relative flex flex-col h-full justify-between">
          <div className="py-5 overflow-y-scroll">
            <div className={'text-2xl text-primary px-5'} onClick={onClose}>
              <FaTimes className={'ml-auto'} />
            </div>
            <ul className="pl-0 block lg:hidden mt-5 mx-5">
              {menuItems.map(({label, url}, index) => (
                <Item key={index} text={label} url={url} />
              ))}
            </ul>
          </div>
          <div className={'cursor-pointer flex items-center text-primary px-3 pb-5 justify-center'}>
            {generateItem('al')} /
            {generateItem('en')}
          </div>
        </article>
      </div>
      <section className=" w-screen h-full cursor-pointer" />
    </aside>
  );
};
export default MobileMenu;
