const al = {
  "header": {
    "home": "Kreu",
    "aboutUs": "Rreth nesh",
    "projects": "Projekte",
    "publications": "Publikime",
    "media": "Media",
    "services": "Shërbime",
    "contacts": "Kontakte",
    "report": "Denonco",
  },
  "aboutUs": {
    "legalFramework": "Kuadri ligjor",
    "legalFrameworkInfo": "AKUK është organ shtetëror i specializuar në fushën e ujësjellës-kanalizimeve, të trajtimit të ujërave të ndotura dhe mbetjeve, në përputhje me kompetencat e përcaktuara në këtë vendim. AKUK mbështet teknikisht politikat e ministrisë përgjegjëse për infrastrukturën, në përputhje me legjislacionin dhe politikat e përcaktuara në strategjitë sektoriale.",
    "mission": "Mision",
    "missionInfo": "AKUK ka si mision zbatimin e politikës specifike shtetërore në sektorin e ujësjellës-kanalizimeve, trajtimin e ujërave të ndotura dhe planifikimin territorial dhe infrastrukturën e mbetjeve ",
    "budget": "Buxheti",
    "budgetInfo": "Informacion mbi buxhetin dhe të dhënat financiare të AKUK",
    "structure": "Struktura",
    "structureInfo": "Struktura dhe organika e AKUK miratohet me urdhër të Kryeministrit, sipas përcaktimeve në legjislacionin në fuqi për organizimin dhe funksionimin e administratës shtetëror ",
    "map": "Harta",
    "mapInfo": "Së shpejti",
    "socialResponsibility": "Përgjegjësi Sociale",
    "socialResponsibilityInfo": "AKUK krahas misionit dhe shërbimeve që ofron, konsideron shumë të rëndësishme Përgjegjësinë Sociale për të qendrueshmeri ne përmirësimin e kushteve të shoqerise",
    "taskForce": "Task Forca",
    "taskForceInfo": "AKUK krahas misionit dhe shërbimeve që ofron, konsideron shumë të rëndësishme Task Forcës për të qendrueshmeri ne përmirësimin e kushteve të shoqerise",
  },
  "projects": {
    "financedByState": "Projekte me financim nga buxheti i shtetit",
    "financedByStateInfo": "Të gjitha projektet e Ujësjellës-kanalizimeve, të ITUN-eve, planifikimit territorial dhe infrastrukturës së mbetjeve, të financuara nga buxheti i shtetit",
    "financedByStateInfoShort": "Të gjitha projektet e Ujësjellës-kanalizimeve, të financuara nga buxheti i shtetit",

    "financedByInternational": "Projekte me financim nga Institucione Ndërkombëtare",
    "financedByInternationalInfo": "Të gjitha projektet e ujësjellës- kanalizimeve, planifikimit territorial dhe infrastrukturës së mbetjeve, të financuara nga institucione financiare ndërkombëtare ",
    "financedByInternationalInfoShort": "Të gjitha projektet e ujësjellës- kanalizimeve, të financuara nga institucione financiare ndërkombëtare ",

    "implants": "Impiante",
    "implantsInfo": "Projekte për ndërtimin/rehabilitimin/zgjerimin e impianteve të trajtimit të ujërave të ndotura",
    "completed": "Projekte të përfunduara",
    "completedInfo": "Projekte të përfunduara të financuara nga buxheti i shtetit ose institucione financiare ndërkombëtare",
  },
  "publications": {
    "transparencyProgram": "Programi i transparencës",
    "transparencyProgramInfo": "Në programin e transparencës janë të specifikuara kategoritë e informacionit të detyrueshëm për t’u bërë publik, mënyra e publikimit, si dhe parashikimet ligjore prej të cilave lind detyrimi për publikim",

    "registerOfRequestsAndResponses": "Regjistri i kërkesave dhe përgjigjeve",
    "registerOfRequestsAndResponsesInfo": "Regjistri i kërkesave për informacion",

    "theRightToInformation": "E drejta e informimit",
    "coordinatorForTheRightToInformation": "Koordinator për të drejtën e informimit",
    "coordinatorForTheRightToInformationInfo": "",

    "tenders": "Tendera",
    "tendersInfo": "Informacion mbi tenderat e AKUK",

    "others": "Të tjera",
    "othersInfo": "Informacione të tjera",

    "reports": "Raporte",
    "reportsInfo": "Raportet periodike lidhur me AKUK dhe shoqërive UK",

    "vacancies": "Vende Vakante",
    "vacanciesInfo": "Informacion mbi vendet vakante të AKUK",

    "expropriation": "Shpronësime",
    "expropriationInfo": "Informacion rreth procedurave te shpronësimëve",
  },
  "readMore": "Lexo më shumë",
  "news": "Lajme",
  "latestNews": "Lajmet e fundit",
  "photoGallery": "Foto galeri",
  "videoGallery": "Video galeri",
  "photosFromVariousProjects": "Foto nga projekte të ndryshme",
  "videosFromVariousProjects": "Video nga projekte të ndryshme",
  "contractor": "Kontraktori",
  "authority": "Autoriteti",
  "value": "Vlera",
  "beneficiary": "Perfitues",
  "population": "Popullsia",
  "waterSupplier": "Ujësjellës",
  "partners": "Partnerët",
  "homeCards": {
    "waterUtilities": "Shoqëri <br /> UK",
    "transparency": "Transparenca",
    "vacancies": "Vende <br /> Vakante",
    "theRightOfInformation": "E drejta <br /> Informimit",
  },
  "homeSlider": {
    "waterUtilities": "Shoqëri UK",
    "actualSites": "Kantiere Aktuale",
    "subscribers": "Mbulimi i Furnizimit me Ujë",
  },
  "structure": {
    "generalDirector": "Drejtori i Pergjithshëm",
    "generalDirectorInfo": "Z. Klevis Jahaj ka kryer studimet pranë Fakultetit Ekonomik në Universitetin e Tiranës, dega Financë. Nga Tetori 2021, z. Jahaj u emërua për të drejtuar Agjencinë Kombëtare Ujësjellës Kanalizime, institucion në varësi të Ministrisë së Infrastrukturës dhe Energjisë.",

    "deputyDirector": "Zv. Drejtor i Pergjithshëm",
    "deputyDirectorInfo": "Z. Flauers Shoshi është diplomuar në Inxhinieri Ndërtimi në Universitetin Politeknik të Tiranës. Që nga viti 2019, Z. Shoshi mban pozicionin e Zëvendës Drejtorit të Agjencisë Kombëtare Ujësjellës Kanalizime në Shqipëri.",

    "directorateOfEconomicAndSupportServices": "Drejtoria Ekonomike dhe Sherbimeve Mbeshtetese",
    "directorateOfEconomicAndSupportServicesInfo": "Z. Florian Demi ka përfunduar studimet në Master Shkencor Kontabilitet dhe Auditim nga Fakulteti i Ekonomisë në Tiranë. Prej tetorit 2021 z. Demi është Drejtor Ekonomik pranë të Agjencisë Kombëtare Ujësjellës Kanalizime në Shqipëri",

    "directorateOfMonitoringAndSupervision": "Drejtoria e Monitorimit dhe Mikqyrjes",
    "directorateOfMonitoringAndSupervisionInfo": "Z. Arben Kacorri është diplomuar në Fakultetin e Shkencave Natyrore “Matematikë-Fizikë “ Universiteti “Eqerem Çabej”, Gjirokastër. Që nga viti 2019, mban pozicionin e Drejtorit të Monitorimit dhe Mikqyrjes pranë Agjencisë Kombëtare Ujësjellës Kanalizime në Shqipëri.",

    "directorateOfInvestmentPlanning": "Drejtoria e Planifikimit te Investimeve",
    "directorateOfInvestmentPlanningInfo": "Z. Jetlir Gjergji është diplomuar në Arkitekturë në Universitetin Politeknik të Tiranës dhe  Master për Planifikim Hapsinor dhe GIS në Universitetin Polis. Prej tetorit 2023 z. Gjergji është Drejtor i Planifikimit të Investimeve në Agjensinë Kombëtare të Ujësjellës Kanalizimeve të Shqipërisë.",

    "directorateOfCoordinationAnalysisAndInformation": "Drejtoria e Koordinimit, Analizes dhe Informacionit",
    "directorateOfCoordinationAnalysisAndInformationInfo": "Znj. Albana Jani ka kryer studimet pranë Fakultetit Ekonomik në Universitetin e Tiranës, dega Ekonomiks. Që nga viti 2019, mban pozicionin e Drejtoreshës së Koordinimit, Analizës dhe Informacionit pranë Agjencisë Kombëtare Ujësjellës Kanalizime (AKUK) në Shqipëri",
  },
  "mr": "Z. ",
  "ms": "Znj. ",
  "organization": "ORGANIGRAMA",
  "salaryCategory": "KATEGORIA E PAGAVE",
}
export default al
