import {combineReducers} from 'redux';
import generalState from './general/Reducer';
import spinnerReducer from './spinner/Reducer';
import appState from "@redux/app/Reducer";

const Reducers = combineReducers({
    generalState,
    appState,
    spinnerReducer,
});

export default Reducers;
