import {OVERWRITE_APP_DATA, RESET_APP_DATA} from '@constants/redux';

const appState = (state = {}, action) => {
    switch (action.type) {
        case OVERWRITE_APP_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_APP_DATA:
            return {};
        default:
            return state;
    }
};

export default appState

