import React, {useEffect} from "react";
import Layout from "@hoc/layouts/Layout";
import PageHeader from "@components/Pages/PageHeader";
import PageListItem from "@components/Pages/ListItem";
import Pagination from "@components/Pages/Pagination";
import {Link} from "react-router-dom";
import DownArrow from "@assets/svg/down_arrow.svg";
import ArrowRight from "@assets/svg/arrow_right.svg";
import {MdOutlinePictureAsPdf} from "react-icons/md";
import useTranslatedField from "../../hooks/useTranslatedField";

const DetailsPageWithFiles = ({item}) => {
  const {getLabel} = useTranslatedField(item)
  return (
    <>
      <PageHeader title={getLabel('title')} />
      <div className={'bg-white shadow-md px-5 pt-5 pb-14'}>
        <hr className={'hidden sm:block sm:w-1/4 my-5 border-gray-700'}/>
        <div className={'flex flex-col sm:flex-row pt-3'}>
          <img src={ArrowRight} className={'w-8 h-8 rotate-90 sm:rotate-0 mb-5 mx-auto sm:mx-10'}/>
          <hr className={'block sm:hidden w-1/4 my-5 border-gray-700'}/>
          <div className={'flex-1 font-light text-justify sm:text-left overflow-y-auto'}>
            <div style={{maxWidth: '80vw'}} dangerouslySetInnerHTML={{__html: getLabel('description')}} />
          </div>
        </div>
        {item?.media?.length > 0 && (
          <>
            <hr className={'w-1/4 my-5 border-gray-700'}/>
            <div className={'flex flex-col sm:flex-row pt-3'}>
              <img src={ArrowRight} className={'w-8 h-8 rotate-90 sm:rotate-0 mx-auto sm:mx-10 mb-5'}/>

              <div className={'flex flex-wrap justify-center'}>
                {item?.media?.map((mediaItem) => (
                  <a className={'py-2 mx-3 mb-5 px-5 text-gray-800 bg-gray-200 hover:text-white hover:bg-green flex shadow-md rounded-r-full rounded-bl-full'} href={mediaItem.original_url} target="_blank">
                    {mediaItem.file_name}
                    <MdOutlinePictureAsPdf className={'ml-2'} size={25} />
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={'h-15 bg-primary rounded-br-full shadow-lg'}/>
    </>
  );
}

export default DetailsPageWithFiles;
