import React, {useEffect} from "react";
import Layout from "@hoc/layouts/Layout";
import {ReactComponent as Cube} from "@assets/svg/cube.svg";
import PageHeader from "@components/Pages/PageHeader";
import ArrowRight from '@assets/svg/arrow_right.svg';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import useSingleModule from "../../hooks/useSingleModule";
import {getMediaUrl} from "@utils/helpers";
import useTranslatedField from "../../hooks/useTranslatedField";

const project = {
  title: 'PROJECT TITLE',
  svg: <Cube />,
  description: 'Agjencia Kombëtare e Ujësjellës-Kanalizime është i vetmi organ shtetëror i specializuar në fushën e furnizimit general ujë të pijshëm, kanalizimeve të ujërave të zëza dhe të bardha si dhe trajtimit të tyre',
  url: 'projects/state',
}

const InfoBadge = ({title, value}) => (
  <div className={'w-full'}>
    <div className={'bg-primary h-24 rounded-lg flex flex-col justify-center items-center text-white'}>
      <h1 className={'font-bold'}>{title?.toUpperCase()}</h1>
      <hr className={'w-1/4 my-2'} />
      <h1>{value}</h1>
    </div>
  </div>
)

const ShowProjectPage = () => {
  const {id} = useParams()
  const {t} = useTranslation();
  const item = useSingleModule('allProjects', id)
  const {getLabel} = useTranslatedField(item)
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-10 lg:px-0 mx-auto'}>
        <PageHeader title={getLabel('title')} />
        <img className={'max-h-124 w-full object-cover my-4'} src={getMediaUrl(item)} style={{borderRadius: '0 0 200px 0'}} />

        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
          <InfoBadge title={t('contractor').toUpperCase()} value={item.contractor} />
          <InfoBadge title={t('authority').toUpperCase()} value={item.authority} />
          <InfoBadge title={t('beneficiary').toUpperCase()} value={item.benefits} />
          <InfoBadge title={t('population').toUpperCase()} value={item.population} />
        </div>
        <hr className={'border-gray-500 hidden md:block md:my-5 mt-5 w-1/3'}/>
        <div className={'flex flex-col md:flex-row py-5'}>
          <img src={ArrowRight} className={'w-10 h-10 md:mr-20 my-8 md:my-0 rotate-90 mx-auto md:mx-0 md:rotate-0'}/>
          <p className={'flex-1'}>{getLabel('description')}</p>
        </div>
      </div>
    </Layout>
  );
}

export default ShowProjectPage;
