import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import {FaRegEnvelope} from "react-icons/fa";
import Input from "@core/inputs/Input";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import TextArea from "@core/textareas/TextArea";
import API from "@utils/plugins/API";
import {fileToBase64, getFileExtension, showError, showSuccess} from "@utils/helpers";
import FileInput from "@core/inputs/FileInput";

const WaterSupplyForm = () => {

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [fileBase64, setFileBase64] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [text, setText] = useState('');

  const handleFileSelect = async (file) => {
    setFile(file || {});
    const base64 = await fileToBase64(file);
    setFileBase64(base64);
  };

  const submit = () => {
    setLoading(true);
    API.post('report-abuse/water-supply', {
      file: fileBase64,
      extension: getFileExtension('name' in file ? file.name : ''),
      address,
      phone,
      email,
      text,
    })
      .then((res) => {
        setLoading(false);
        showSuccess('Të dhënat u ruajtën')
        setFile({})
        setFileBase64('')
        setPhone('')
        setEmail('')
        setAddress('')
        setText('')
      }).catch(() => {
      setLoading(false);
      showError('Gabim! Ju lutem kontrolloni formën')
    }).finally(() => setLoading(false))
  }

  return (
    <div>
      <FileInput
        title="Ngarko Foto ose Video"
        handleFileSelect={handleFileSelect}
        fileName={'name' in file ? file.name : ''}
      />
      <Input className={'my-2'} label={'Specifiko rrugën/zonën/qytetin'} value={address} onChange={setAddress} />
      <Input type={'tel'} className={'my-2'} label={'Telefon'} value={phone} onChange={setPhone} />
      <Input type={'email'} className={'my-2'} label={'Email'} value={email} onChange={setEmail} />
      <TextArea className={'mt-2 mb-5'} label={'Përshkruaj problematikën'} value={text} onChange={setText} />
      <DefaultButton loading={loading} label={'Dergo'} onClick={submit}  />
      <div className={'text-xs text-primary mt-5'}>*Denoncimet e raportuara do të trajtohen në mënyrë konfidenciale nga institucionet kompetente!</div>
    </div>
  );
}

export default WaterSupplyForm;
