import React from "react";

export const menuItems = [
  {
    label: 'home',
    url: '/',
  },
  {
    label: 'aboutUs',
    url: '/about-us',
    children: [
      {
        label: 'aboutUs.legalFramework',
        url: '/about-us/legal-framework',
      },
      {
        label: 'aboutUs.mission',
        url: '/about-us/mission',
      },
      {
        label: 'aboutUs.budget',
        url: '/about-us/budget',
      },
      {
        label: 'aboutUs.map',
        url: '/about-us/map',
      },
      {
        label: 'aboutUs.structure',
        url: '/about-us/structure',
      },
      {
        label: 'aboutUs.taskForce',
        url: '/about-us/task-force',
      },
    ]
  },
  {
    label: 'projects',
    url: '/projects',
    children: [
      {
        label: 'projects.financedByState',
        url: '/projects/state',
      },
      {
        label: 'projects.financedByInternational',
        url: '/projects/international',
      },
      {
        label: 'projects.implants',
        url: '/projects/implants',
      },
      {
        label: 'projects.completed',
        url: '/projects/finished',
      },
    ]
  },
  {
    label: 'publications',
    url: '/publications',
    children: [
      {
        label: 'publications.transparencyProgram',
        url: '/publications/transparency-program',
      },
      {
        label: 'publications.registerOfRequestsAndResponses',
        url: '/publications/request-register',
      },
      {
        label: 'publications.coordinatorForTheRightToInformation',
        url: '/publications/info',
      },
      {
        label: 'publications.expropriation',
        url: '/publications/expropriation',
      },
      {
        label: 'publications.tenders',
        url: '/publications/tenders',
      },
      {
        label: 'publications.vacancies',
        url: '/publications/vacancies',
      },
      {
        label: 'publications.others',
        url: '/publications/other',
      },
      {
        label: 'publications.reports',
        url: '/publications/reports',
      },
    ]
  },
  {
    label: 'media',
    url: '/media',
  },
  {
    label: 'services',
    url: '/services',
  },
  {
    label: 'contacts',
    url: '/contacts',
  },
  {
    label: 'report',
    url: '/denonco',
    alert: true,
  },
];
