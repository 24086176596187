import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import {FaRegEnvelope} from "react-icons/fa";
import {AiOutlineUser} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const items = [
  {
    title: 'structure.generalDirector',
    gender: "mr",
    name: 'Klevis Jahaj',
    email: 'klevis.jahaj@akuk.gov.al',
    description: 'structure.generalDirectorInfo'
  },
  {
    title: 'structure.deputyDirector',
    gender: "mr",
    name: 'Flauers Shoshi',
    email: 'flauers.shoshi@akuk.gov.al',
    description: 'structure.deputyDirectorInfo'
  },
  {
    title: 'structure.directorateOfEconomicAndSupportServices',
    gender: "mr",
    name: 'Florian Demi',
    email: 'florian.demi@akuk.gov.al',
    description: 'structure.directorateOfEconomicAndSupportServicesInfo'
  },
  {
    title: 'structure.directorateOfMonitoringAndSupervision',
    gender: "mr",
    name: 'Arben Kacorri ',
    email: 'arben.kacorri@akuk.gov.al',
    description: 'structure.directorateOfMonitoringAndSupervisionInfo'
  },
  {
    title: 'structure.directorateOfInvestmentPlanning',
    gender: "mr",
    name: 'Jetlir Gjergji ',
    email: 'jetlir.gjergji@akuk.gov.al',
    description: 'structure.directorateOfInvestmentPlanningInfo'
  },
  {
    title: 'structure.directorateOfCoordinationAnalysisAndInformation',
    gender: "ms",
    name: 'Albana Jani ',
    email: 'albana.jani@akuk.gov.al',
    description: 'structure.directorateOfCoordinationAnalysisAndInformationInfo'
  },
]

const Item = ({isActive, item, title, onClick}) => (
  <div className={`flex-1 border-b flex px-5 lg:px-10 py-5 items-center cursor-pointer ${isActive ? 'bg-green text-white' : 'text-primary bg-white hover:bg-gray-200'} duration-500 transition-all`} onClick={() => onClick(item)}>
    <p className={'text-lg font-semibold'}>{title}</p>
  </div>
)

const StructurePage = () => {
  const {t} = useTranslation()
  const [activeItem, setActiveItem] = useState({});

  const handleClick = (item) => {
    setActiveItem(item)
    const element = document.getElementById("main-content");
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  useEffect(() => {
    setActiveItem(items[0])
  }, [])

  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <div className={'h-14 bg-primary flex items-center text-xl font-extrabold text-white px-7'}>
          {t('aboutUs.structure')?.toUpperCase()}
        </div>
        <div className={'md:h-140 flex flex-col md:flex-row'}>
          <div className={'flex-1 flex flex-col'}>
            {items.map((item, index) => (
              <Item key={index} isActive={item.email === activeItem.email} item={item} title={t(item.title)} onClick={handleClick} />
            ))}
          </div>
          <div id={'main-content'} className={'flex-1 bg-primary text-white px-10 py-10 md:py-0 rounded-br-8xl md:rounded-br-extreme'}>
            <AiOutlineUser size={60} />
            <h1 className={'text-xl font-bold mt-3'}>{t(activeItem.gender)}{activeItem.name}</h1>
            <p className={'text-lg font-thin mt-5 mb-10 mr-0 lg:mr-10'} dangerouslySetInnerHTML={{ __html: t(activeItem.description) }} />
            <FaRegEnvelope size={25} />
            <a href={`mailto:${activeItem.email}`} className={'mt-3 font-thin hover:underline'}>{activeItem.email}</a>
          </div>
        </div>

        <div className={'my-5 flex flex-col md:flex-row'}>
          <a href="/organization.pdf" target="_blank" className={'h-30 rounded-2xl w-full border flex justify-center items-center text-white bg-primary hover:bg-green mr-0 md:mr-2 mt-5'}>
            {t('organization')}
          </a>
          <a href="/salary.pdf" target="_blank" className={'h-30 rounded-2xl w-full border flex justify-center items-center text-white bg-primary hover:bg-green ml-0 md:ml-2 mt-5'}>
            {t('salaryCategory')}
          </a>
        </div>
      </div>
    </Layout>
  );
}

export default StructurePage;
