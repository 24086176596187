import React from "react";
import ProjectsImage from "@assets/svg/projects_pie.svg";
import {useTranslation} from "react-i18next";
import HomeProjectsList from "@components/Home/HomeProjectsList";

const HomeProjects = () => {
  const {t} = useTranslation()
  return (
    <div className={'w-full sm:w-5/6 lg:w-4/5 mx-auto'}>
      <div
        className="3xl:max-w-7xl 3xl:mx-auto flex md:flex-row xs:flex-col font-inter place-content-between xl:pt-20 pb-15">
        <div className="flex-col place-content-center flex-1 sm:mt-9 xs:mt-18.5 hidden md:flex">
          <div className="flex place-content-center relative w-full lg:h-138 xs:h-78 payment-utilities">
            <img src={ProjectsImage} alt=""/>
          </div>
        </div>
        <div className="flex-1 xl:p-0 md:pt-8 xs:pt-10.5 ml-10 sm:ml-20">
          <div className="lg:w-122 sm:w-80 text-left font-inter xl:text-5xl md:text-3xl xs:text-2xl font-black mb-10">
            {t('header.projects')}
          </div>
          <HomeProjectsList/>
        </div>
      </div>
    </div>
  )
}

export default HomeProjects;
