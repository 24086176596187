import React from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {FaCircleNotch} from "react-icons/fa";

const FullPageSpinner = () => {
  const isActive = useSelector((state) =>
    _.get(state, 'spinnerReducer.show', false),
  );
  const loaderMessage = useSelector((state) =>
    _.get(state, 'spinnerReducer.text', ''),
  );

  if (!isActive) {
    return null
  }

  return (
    <div className="w-full h-full fixed flex justify-center items-center top-0 left-0 bg-black40 z-50">
      <span className="text-green flex flex-col items-center relative text-center">
        <FaCircleNotch size={50} className={'animate-spin-slow relative mb-2'} />
        {loaderMessage}
      </span>
    </div>
  );
};

export default FullPageSpinner;
