import React from "react";
import Layout from "@hoc/layouts/Layout";
import PageHeader from "@components/Pages/PageHeader";
import {useTranslation} from "react-i18next";
import {MdOutlinePictureAsPdf} from "react-icons/md";

const ShowTransparencyProgramPage = () => {
  const {t} = useTranslation()

  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-3 sm:px-5 md:px-10 lg:px-0 mx-auto'}>
        <PageHeader title={t('publications.transparencyProgram')} />
        <div className={'w-full p-10 bg-white'}>
          <h1 className={'text-xl font-bold'}>1. HYRJE</h1>
          <div className={'px-4 mt-4'}>
            <p className={'text-sm text-gray-600'}>
              Bazuar në nenin 7 të ligjit nr. 119/2014 “Për të drejtën e informimit” (në vijim “LDI”), Autoriteti Publik (në vijim “AP”) duhet të publikojë Programin e Transparencës (në vijim “PT”) të miratuar nga Komisioneri për të Drejtën e Informimit dhe Mbrojtjen e të Dhënave Personale (në vijim “Komisioneri”). AP duhet të publikoj informacion sipas veprimtarisë së tij funksionale si dhe të rishikoj e përditësoj PT në çdo rast të ndryshimit të aktivitetit institucional, kuadrit ligjor rregullator apo çdo elementi tjetër që ka lidhje me transparencën proaktive.
              Në PT janë të specifikuara kategoritë e informacionit të detyrueshëm për t’u bërë publik. Informacioni publik pa kërkesë duhet të jetë: i plotë, i saktë, i përditësuar, i thjeshtë në konsultim, i kuptueshëm, lehtësisht i aksesueshëm dhe i pajtueshëm me dokumentet origjinale në posedim të A.P. Gjithashtu, përpara publikimit të informacionit AP duhet të vlerësojë nësë ka ndonjë kufizim ligjor për publikim.
              Aktet që përmbajnë rregulla, norma apo kufizime të të drejtave dhe lirive themelore të individit, si dhe me efekt të drejtpërdrejtë për ta, bëhen publike me anë të afishimit apo postimit në faqen zyrtare të internetit, brenda 48 orëve prej miratimit të aktit nga AP.
              Në programin e transparencës janë të specifikuara kategoritë e informacionit të detyrueshëm për t’u bërë publik, mënyra e publikimit, si dhe parashikimet ligjore prej të cilave lind detyrimi për publikim. Në publikimin e informacionit, AP duhet të mbajnë parasysh interesin e publikut si dhe të mundësojnë aksesimin lehtësisht në informacionin publik.
            </p>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>2. RRETH AUTORITETIT</h1>
          <div className={'px-4 mt-4'}>
            <div className={'py-2'}>
              <a href={'/about-us/structure'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Struktura organizative/Organigrama</a>
            </div>
            <div className={'py-2'}>
              <a href={'/salary.pdf'} target={'_blank'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Struktura e pagave</a>
            </div>
            <div className={'py-2'}>
              <a href={'/about-us/mission'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Funksionet dhe detyrat e A.P</a>
            </div>
            <div className={'py-2'}>
              <a className={'font-semibold'}>CV-të dhe pagat e nënpunësve që janë subjekt deklarimi sipas ligjit, përfshirë dhe anëtarët e kabinetit të titullarit të AP, përshkrim të  procedurës së zgjedhjes, kompetencat dhe detyrat e tyre</a>
              {/*TODO: ADD CV*/}
              <div className="flex flex-wrap mt-4">
                <a className={'py-2 mx-3 mb-5 px-5 text-gray-800 bg-gray-200 hover:text-white hover:bg-green flex shadow-md rounded-r-full rounded-bl-full'} href={'/klevis_jahaj_cv.pdf'} target="_blank">
                  Klevis Jahaj
                  <MdOutlinePictureAsPdf className={'ml-2'} size={25} />
                </a>
                <a className={'py-2 mx-3 mb-5 px-5 text-gray-800 bg-gray-200 hover:text-white hover:bg-green flex shadow-md rounded-r-full rounded-bl-full'} href={'/flauers_shoshi_cv.pdf'} target="_blank">
                  Flauers Shoshi
                  <MdOutlinePictureAsPdf className={'ml-2'} size={25} />
                </a>
              </div>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Procedura që ndjekin funksionarët e lartë të AP për të marrë vendime</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Procedurat dhe mekanizmat për të bërë kërkesë dhe ankesë që lidhen me veprimet ose mosveprimet e AKUK ( Përfshirë adresën postare/elektronike për depozitimin e kërkesave dhe ankesave – Afatet dhe mënyrën e kthimit të përgjigjeve)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>3. KUADRI LIGJOR RREGULLATOR</h1>
          <div className={'px-4 mt-4'}>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Legjislacion ndërkombëtar</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <a href={'/about-us/legal-framework'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Legjislacion kombëtar</a>
            </div>
            <div className={'py-2'}>
              <a href={'/about-us/legal-framework'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Akte nënligjore</a>
            </div>
            <div className={'py-2'}>
              <a target={'_blank'} href={'https://backoffice.akuk.gov.al/storage/163/Rregullore-AKUM-(1).pdf'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Rregullore/Kodi i sjelljes</a>
            </div>
            <div className={'py-2'}>
              <a target={'_blank'} href={'https://backoffice.akuk.gov.al/storage/163/Rregullore-AKUM-(1).pdf'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Aktet që përmbajnë rregulla, norma apo kufizime të të drejtave dhe lirive themelore të individit</a>
            </div>
            <div className={'py-2'}>
              <a href={'/publications/other'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Strategji</a>
            </div>
            <div className={'py-2'}>
              <a href={'/publications/reports'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Raport Vjetor</a>
            </div>
            <div className={'py-3'}>
              <p className={'font-semibold'}>Manuale/dokument politikash, që ka lidhje me funksionet e AKUK dhe që prek publikun</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <div className={'mt-8'}>
            <a href={'/publications/info'} className={'text-primary-400  hover:text-primary-200 text-xl font-bold underline'}>4. E DREJTA PËR T'U INFORMUAR DHE PËR T'U ANKUAR</a>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>5. MEKANIZMAT KONTROLLUES DHE MONITORUES QË VEPROJNË MBI AKUK</h1>
          <div className={'px-4 mt-4'}>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Rezolutë e Kuvendit të Shqipërisë (nëse ka të tillë)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Planet strategjike të punës</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Raporte monitorimi/auditimi (p.sh:KLSH)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Dokumente të tjera që përmbajnë tregues të performancës së AKUK</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>6.	INFORMACION MBI BUXHETIN DHE TË DHËNAT FINANCIARE TË AKUK</h1>
          <div className={'px-4 mt-4'}>
            <div className={'py-2'}>
              <a href={'/about-us/budget'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Buxheti, plani i shpenzimeve për vitin financiar në vijim dhe vitet e kaluara</a>
            </div>
            <div className={'py-2'}>
              <a href={'/about-us/budget'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Raport vjetor për zbatimin e buxhetit dhe anekset</a>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Donacione, sponsorizime</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Dokumente që tregojnë gjendjen e shlyerjes së detyrimeve nga subjektet e licencuara</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>7.	INFORMACION PËR PROCEDURAT E PROKURIMIT/PROCEDURAT KONKURRUESE TË KONCESIONIT/PARTNERITETIT PUBLIK PRIVAT</h1>
          <div className="px-4 mt-4">
            <div className={'py-2'}>
              <p className={'font-semibold'}>Lista e kontratave të lidhura (përfshirë shumën e kontraktuar, palët kontraktuese dhe përshkrimin e shërbimeve)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Regjistri i parashikimeve të prokurimit publik</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Regjistri i realizimeve të prokurimit publik</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Informacion mbi zbatimin dhe monitorimin e kontratave, si dhe udhëzues e politika të ndryshme</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>8.	SHËRBIMET QË OFROHEN NGA AKUK</h1>
          <div className="px-4 mt-4">
            <p className={'text-sm text-gray-600'}>Nuk ka</p>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>9.	PROCEDURAT/MEKANIZMAT PËR TË DHËNË MENDIME LIDHUR ME PROCESIN E HARTIMIT TË AKTEVE LIGJORE/NËNLIGJORE, POLITIKAVE PUBLIKE APO NË LIDHJE ME USHTRIMIN E FUNKSIONEVE TË AKUK </h1>
          <div className="px-4 mt-4">
            <div className={'py-2'}>
              <p className={'font-semibold'}>Njoftimi dhe konsultimi publik (Linku)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Komente/Rekomandime/Opinione të palëve të interesuara/grupe interesi</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Shqyrtimi i komenteve dhe rekomandimeve</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Raportet vjetore për transparencën në procesin e vendimmarrjes</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Procedura/mekanizma për të dhënë mendime lidhur me hartimin e politikave publike apo ushtrimin e funksioneve të AP</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Emër mbiemër të koordinatorit për konsultimin publik, adresë postare/elektronike</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>10. SISTEMI I MBAJTJES SË DOKUMENTACIONIT, LLOJET DHE FORMAT E DOKUMENTEVE</h1>
          <div className="px-4 mt-4">
            <div className={'py-2'}>
              <p className={'font-semibold'}>Lista e dokumenteve që administrohen nga AKUK me afatet e ruajtjes</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
            <div className={'py-2'}>
              <p className={'font-semibold'}>Formën e dokumenteve/informacioneve (elektronik, kopje fizike, audio, video, foto)</p>
              <p className={'text-sm text-gray-600 mt-1'}>Nuk ka</p>
            </div>
          </div>
          <div className={'mt-8'}>
            <a href={'/publications/request-register'} className={'text-primary-400  hover:text-primary-200 text-xl font-bold underline'}>11. REGJISTRI I KËRKESAVE DHE PËRGJIGJEVE I PËRDITËSUAR (SIPAS MODELIT TË REGJISTRIT TË MIRATUAR NGA KOMISIONERI)</a>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>12. NDIHMA SHOQËRORE/SUBVENCIONE TË DHËNA NGA AKUK</h1>
          <div className="px-4 mt-4">
            <p className={'text-sm text-gray-600'}>Nuk ka</p>
          </div>
          <h1 className={'text-xl font-bold mt-8'}>13. INFORMACIONE/DOKUMENTE QË KËRKOHEN MË SHPESH DHE ATO QË GJYKOHEN TË DOBISHME PËR PUBLIKIM NGA AKUK</h1>
          <div className="px-4 mt-4">
            <div className={'py-2'}>
              <a href={'/publications/other'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Njoftime të ndryshme</a>
            </div>
            <div className={'py-2'}>
              <a href={'/publications/vacancies'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Njoftime për procedurat e rekrutimit/pranimit në shërbim civil</a>
            </div>
            <div className={'py-2'}>
              <a href={'/publications/reports'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Raporte</a>
            </div>
            <div className={'py-2'}>
              <a href={'/publications'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Publikime</a>
            </div>
            <div className={'py-2'}>
              <a href={'/projects'} className={'underline text-primary-400  hover:text-primary-200 font-semibold'}>Projekte</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShowTransparencyProgramPage;
