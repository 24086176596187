// import React, {useState} from "react";
//
// const Item = ({isActive, number, onClick}) => (
//   <div className={`mx-2 cursor-pointer ${isActive ? 'font-extrabold' : 'font-thin'}`} onClick={() => onClick(number)}>{number}</div>
// )
//
// const Pagination = ({numOfPages = 3}) => {
//   const [activePage, setActivePage] = useState(1)
//
//   const renderItems = () => {
//     let itemsToRender = [];
//     for (let i = 1; i <= numOfPages; i++) {
//       itemsToRender.push(<Item key={i} isActive={activePage === i} number={i} onClick={setActivePage} />)
//     }
//     return itemsToRender;
//   }
//
//   return (
//     <div className={'bg-primary w-1/2 ml-auto flex rounded-br-full justify-end items-center text-base md:text-xl font-semibold text-white py-4 pl-4 pr-14 pr-7'}>
//       {renderItems()}
//     </div>
//   );
// }
//
// export default Pagination;

import React from "react";
import {usePagination} from "../../hooks/usePagination";

const Item = ({isActive, number, onClick, currentActive}) => {
  return (
    <div className={`mx-2 cursor-pointer ${isActive ? 'font-extrabold' : 'font-thin'}`} onClick={() => onClick(number)}>{number}</div>
  )
}

const Pagination = ({
                      onPageChange,
                      totalCount,
                      siblingCount = 1,
                      currentPage,
                      pageSize,
                    }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const handleChange = (number) => {
    if (number === '...') return
    onPageChange(number);
  };

  return (
    <div className={'bg-primary w-1/2 ml-auto flex rounded-br-full justify-end items-center text-base md:text-xl font-semibold text-white py-4 pl-4 pr-14 pr-7'}>
      {paginationRange.map((pageNumber, index) => {
        return (
          <Item key={index} currentActive={currentPage} isActive={pageNumber === currentPage} number={pageNumber} onClick={handleChange} />
        );
      })}
    </div>
  );
}

export default Pagination;
