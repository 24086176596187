import React from "react";
import GIZ from "@assets/images/Partners/giz.png";
import KFW from "@assets/images/Partners/kfw.png";
import SWISS from "@assets/images/Partners/swiss.png";
import WORLDBANK from "@assets/images/Partners/worldbank.png";
import AFD from "@assets/images/Partners/afd.png";
import 'react-multi-carousel/lib/styles.css';
import {useTranslation} from "react-i18next";

const Partner = ({partner, ...props}) => {
  return (
    <div className={'flex items-center opacity-60 hover:opacity-100 m-5'}>
      <img src={partner.image}
           alt={partner.name}
           className="w-40 mx-auto"
      />
    </div>
  )
}

const HomePartners = () => {
  const {t} = useTranslation()
  const partnersImages = [
    {
      image: GIZ,
      name: 'GIZ',
    },
    {
      image: KFW,
      name: 'KFW',
    },
    {
      image: SWISS,
      name: 'SWISS'
    },
    {
      image: WORLDBANK,
      name: 'WORLDBANK'
    },
    {
      image: AFD,
      name: 'AFD'
    },
  ];


  return (
    <div className={'w-full py-10 px-5'}>
      <h1 className={'text-3xl md:text-4xl font-bold text-center text-primary mb-15 sm:mb-10'}>{t('partners')}</h1>
      <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-10'}>
        {partnersImages.map((partner, key) => (
          <Partner id={partner.name} key={key} partner={partner}/>
        ))}
      </div>
    </div>
  )
}

export default HomePartners;
