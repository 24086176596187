import React, {useLayoutEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import useActiveMenuItem from "hooks/useActiveMenuItem";
import {useTranslation} from "react-i18next";

const ChildItem = ({item}) => {
  const {t} = useTranslation();
  return (
    <Link to={item.url} className={'text-gray-600 text-sm hover:shadow-lg font-semibold px-3 hover:bg-green hover:text-white cursor-pointer py-1 rounded-md font-light'}>
      {t(item.label)}
    </Link>
  )
}

const HeaderItem = ({item}) => {
  const {t} = useTranslation();
  const isActive = useActiveMenuItem(item.url, !item?.children)
  const [isOverButton, setIsOverButton] = useState(false);
  const [isOverList, setIsOverList] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [isTouchInput, setIsTouchInput] = useState();
  const button = useRef(null);

  useLayoutEffect(() => {
    if (isOpen && !isOverButton && !isOverList && !isTouchInput) {
      button.current.click();
      setIsOpen(false);
    } else if (!isOpen && (isOverButton || isOverList) && !isTouchInput) {
      button.current.click();
      setIsOpen(true);
    }
  }, [isOverButton, isOverList]);

  return (
    <div ref={button}
         onTouchStart={() => {
           setIsTouchInput(true);
         }}
         onMouseEnter={() => {
           setIsOverButton(true);
         }}
         onMouseLeave={() => {
           setIsOverButton(false);
         }}
         onKeyDown={() => {
           setIsOpen(!isOpen);
         }} className={'z-50'}>
      <Link to={item.url} className={`text-white relative px-3 text-center hover:bg-primary-400 cursor-pointer py-1 rounded-xl ${isActive ? 'font-extrabold' : 'font-light'} ${item.alert && 'bg-red-600 font-bold'}`}>
        {t(`header.${item.label}`)}
      </Link>
      {isOpen && item?.children && (
        <div className={'absolute pt-2'}>
          <div className={'bg-white-transparent flex flex-col rounded-lg px-3 py-3 shadow-2xl'} onMouseEnter={() => {
            setIsOverList(true);
          }}
               onMouseLeave={() => {
                 setIsOverList(false);
               }}>
            {item.children.map((child, index) => (
              <ChildItem key={index} item={child} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderItem;

