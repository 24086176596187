import React from "react";
import Layout from "@hoc/layouts/Layout";
import PageHeader from "@components/Pages/PageHeader";
import PageListItem from "@components/Pages/ListItem";
import Pagination from "@components/Pages/Pagination";
import ArrowRight from "@assets/svg/arrow_right.svg";

const MissionItem = ({text}) => (
  <div className={'flex p-5 my-5 bg-item-bg'}>
    <div className={'mr-10 flex flex-col items-center'}>
      <img src={ArrowRight} className={'w-8 h-8'}/>
    </div>
    <p className={'flex-1 font-light text-justify'}>
      {text}
    </p>
  </div>
)

const MissionPage = () => {
  return (
    <Layout>
      <div className={'my-10 w-full lg:w-4/5 px-6 md:px-10 lg:px-0 mx-auto'}>
        <PageHeader title={'Mision dhe Historiku'} />
        <div className={'flex flex-col sm:flex-row p-5 mt-5'}>
          <div className={' sm:mr-20 flex flex-col items-center mx-auto sm:mx-0'}>
            <h1 className={'mb-2 font-extrabold text-gray-700'}>HISTORIKU</h1>
            <img src={ArrowRight} className={'w-8 h-8 rotate-90 sm:rotate-0 my-2 sm:my-0'}/>
          </div>
          <p className={'flex-1 mt-2 font-light text-justify sm:text-left'}>
            Agjencia Kombëtare e Ujësjellës-Kanalizime është i vetmi organ shtetëror i specializuar në fushën e furnizimit me ujë të pijshëm, kanalizimeve të ujërave të zëza dhe të bardha si dhe trajtimit të tyre (infrastrukturës ujore). Ajo mbështet teknikisht politikat e Ministrisë së Infrastrukturës dhe Energjisë, në përputhje me legjislacionin dhe politikat e Qeverisë të përcaktuara në strategjitë sektoriale.
            <br/>
            <br/>
            Roli i AKUM në fushën e veprimtarisë së saj është që të realizoje nëpërmjet autoritetit të saj ligjor dhe teknik, koordinimin dhe monitorimin e aktivitetit për sigurimin e furnizimit me ujë, kanalizimeve te ujërave të përdorura dhe trajtimit të tyre për të gjithë popullsinë e vendit, në bashkëpunim me pushtetet vendore dhe subjektet e menaxhimit të infrastrukturës ujore.
          </p>
        </div>
        <hr className={'w-1/3 border-gray-700 my-10'}/>
        <div className={'flex flex-col sm:flex-row px-5 pt-5 pb-3'}>
          <div className={'sm:mr-20 flex flex-col items-center mx-auto sm:mx-0 '}>
            <h1 className={'mb-2 font-extrabold text-gray-700 '}>MISIONI</h1>
            <img src={ArrowRight} className={'w-8 h-8 rotate-90 sm:rotate-0 mb-4 mt-2 sm:my-0 sm:hidden'}/>
          </div>
          <p className={'flex-1 font-light'}>
            Për të realizuar misionin e saj në mbështetje të kuadrit të përgjithshëm të veprimtarisë së Ministrisë, AKUM - ja:
          </p>
        </div>
        <MissionItem text={'Harton kuadrin strategjik për zhvillimin dhe menaxhimin e sektorit të ujësjellës kanalizimeve në vendin tonë.'} />
        <MissionItem text={'Propozon planifikimin vjetor të investimeve nga Buxheti i Shtetit duke u mbështetur në nevojat që paraqesin Njësite e Qeverisjes Vendore / Sh.a Ujësjellës Kanalizime dhe e paraqet për aprovim Ministrit të Infrastrukturës dhe Energjisë.'} />
        <MissionItem text={'Mbështet general financim, nëpërmjet Ministrinë e Infrastrukturës dhe Energjisë, nevojat për kosto lokale, takse doganore dhe rimbursim TVSH-je, kontratat e miratuara të financuara nga donatoret e huaj.'} />
        <MissionItem text={'Propozon standartet dhe specifikimet teknike për Ujësjellësa dhe kanalizime dhe i dërgon për miratim në organet përkatëse.'} />
        <MissionItem text={'Harton dhe përgatit projekt aktet ligjore dhe nënligjore që kanë të bëjnë general fushën e administrimit dhe të shpërndarjes së ujit të pijshëm, largimit të organizuar dhe trajtimin e ujërave të përdorura.'} />
      </div>
    </Layout>
  );
}

export default MissionPage;
