import React from "react";
import Layout from "@hoc/layouts/Layout";
import PageChildrenPreview from "@components/Pages/PageChildrenPreview";
import {ReactComponent as Building} from "@assets/svg/building.svg";
import {ReactComponent as Graph} from "@assets/svg/graph.svg";
import {ReactComponent as Database} from "@assets/svg/database.svg";
import {ReactComponent as Map} from "@assets/svg/map.svg";
import {ReactComponent as Pattern} from "@assets/svg/pattern.svg";
import {ReactComponent as UserPin} from "@assets/svg/user_pin.svg";

const items = [
  {
    title: 'aboutUs.legalFramework',
    svg: <Building />,
    url: 'about-us/legal-framework',
    description: 'aboutUs.legalFrameworkInfo',
  },
  {
    title: 'aboutUs.mission',
    svg: <Graph />,
    url: 'about-us/mission',
    description: 'aboutUs.missionInfo',
  },
  {
    title: 'aboutUs.budget',
    svg: <Database />,
    url: 'about-us/budget',
    description: 'aboutUs.budgetInfo',
  },
  {
    title: 'aboutUs.map',
    svg: <Map />,
    url: 'about-us/map',
    description: 'aboutUs.mapInfo',
  },
  {
    title: 'aboutUs.structure',
    svg: <Pattern />,
    url: 'about-us/structure',
    description: 'aboutUs.structureInfo',
  },
  {
    title: 'aboutUs.taskForce',
    svg: <UserPin />,
    url: 'about-us/task-force',
    description: 'aboutUs.taskForceInfo',
  },
]

const AboutUsPage = () => {

  return (
    <Layout hasBackground>
      <div className={'my-10 w-full lg:w-4/5 xl:w-2/3 px-10 lg:px-0 mx-auto'}>
        <PageChildrenPreview items={items} />
      </div>
    </Layout>
  );
}

export default AboutUsPage;
