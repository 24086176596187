import {useSelector} from "react-redux";
import _ from "lodash";
import {useMemo} from "react";

const useSingleModule = (module, id) => {
  const allData = useSelector((state) =>
    _.get(state, `generalState.${module}`, [])
  );

  const mappedData = []
  allData.forEach((item) => {
    mappedData.push(item)
    item?.children?.forEach((childItem) => {
      mappedData.push(childItem)
    })
  })

  const item = useMemo(() => {
    return mappedData.find((item) => {
      return item.id == id
    })
  }, [id, allData])

  return item || {}
};

export default useSingleModule;
