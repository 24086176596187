import React from "react";
import Layout from "@hoc/layouts/Layout";
import PageChildrenPreview from "@components/Pages/PageChildrenPreview";
import {ReactComponent as Building} from "@assets/svg/building.svg";
import {ReactComponent as WebStories} from "@assets/svg/web_stories.svg";
import {ReactComponent as Cube} from "@assets/svg/cube.svg";
import {ReactComponent as OpenBook} from "@assets/svg/open_book.svg";
import {ReactComponent as Receipt} from "@assets/svg/receipt.svg";
import {ReactComponent as UserCircle} from "@assets/svg/user_circle.svg";
import {ReactComponent as Different} from "@assets/svg/different.svg";
import {ReactComponent as Reports} from "@assets/svg/reports.svg";

const items = [
  {
    title: 'publications.transparencyProgram',
    svg: <Building width={25} />,
    url: 'publications/transparency-program',
    description: 'publications.transparencyProgramInfo',
  },
  {
    title: 'publications.registerOfRequestsAndResponses',
    svg: <WebStories width={25} />,
    url: 'publications/request-register',
    description: 'publications.registerOfRequestsAndResponsesInfo',
  },
  {
    title: 'publications.coordinatorForTheRightToInformation',
    svg: <Cube width={25} />,
    url: 'publications/info',
    description: 'publications.coordinatorForTheRightToInformationInfo',
  },
  {
    title: 'publications.expropriation',
    svg: <OpenBook width={25} />,
    url: 'publications/expropriation',
    description: 'publications.expropriationInfo',
  },
  {
    title: 'publications.tenders',
    svg: <Receipt width={25} />,
    url: 'publications/tenders',
    description: 'publications.tendersInfo',
  },
  {
    title: 'publications.vacancies',
    svg: <UserCircle width={25} />,
    url: 'publications/vacancies',
    description: 'publications.vacanciesInfo',
  },
  {
    title: 'publications.others',
    svg: <Different width={25} />,
    url: 'publications/other',
    description: 'publications.othersInfo',
  },
  {
    title: 'publications.reports',
    svg: <Reports width={25} />,
    url: 'publications/reports',
    description: 'publications.reportsInfo',
  },
]

const PublicationsPage = () => {
  return (
    <Layout hasBackground>
      <div className={'my-10 w-full lg:w-4/5 xl:w-2/3 px-10 lg:px-0 mx-auto'}>
        <PageChildrenPreview items={items} />
      </div>
    </Layout>
  );
}

export default PublicationsPage;
