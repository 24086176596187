import React from 'react';
import Cube from "@assets/svg/cube.svg";
import DollarRotate from "@assets/svg/dollar_rotate.svg";
import Webhook from "@assets/svg/webhook.svg";
import Map from "@assets/svg/map.svg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ProjectItem = (props) => {
  const {project} = props;
  return (
    <Link to={project.to} className="flex justify-self-start pb-8 hover:opacity-60">
      <div
        className="xl:w-16 xl:h-16 xl:rounded-2xl xs:w-10 xs:h-10 xs:rounded-lg bg-item-bg flex justify-center items-center">
        <img className="xl:w-8 xl:h-8 xs:w-6 xs:h-6" src={project.image}/>
      </div>
      <div className="xl:ml-8 xs:ml-6 lg:w-122 xs:w-68">
        <div className="font-inter xl:text-lg xs:text-base font-black">
          {project.title}
        </div>
        <div className="pt-1 font-inter font-normal text-sm text-brand-gray">
          {project.description}
        </div>
      </div>
    </Link>
  )
}

const HomeProjectsList = () => {
  const {t} = useTranslation()

  const utilities = [
    {
      image: Cube,
      title: t('projects.financedByState'),
      description: t('projects.financedByStateInfoShort'),
      to: '/projects/state',
    },
    {
      image: DollarRotate,
      title: t('projects.financedByInternational'),
      description: t('projects.financedByInternationalInfoShort'),
      to: '/projects/international',
    },
    {
      image: Webhook,
      title: t('projects.implants'),
      description: t('projects.implantsInfo'),
      to: '/projects/implants',
    },
    {
      image: Map,
      title: t('projects.completed'),
      description: t('projects.completedInfo'),
      to: '/projects/finished',
    },
  ]
  return (
    <div>
      {utilities.map((project, key) => (
        <ProjectItem key={key} project={project}/>
      ))}
    </div>
  )
}

export default HomeProjectsList;

