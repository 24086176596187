import {OVERWRITE_GENERAL, RESET_GENERAL} from '@constants/redux';

export const overwriteData = (payload) => {
    return {
        type: OVERWRITE_GENERAL,
        payload,
    };
};

export const resetData = () => {
    return {
        type: RESET_GENERAL,
    };
};
