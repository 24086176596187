import React, {useEffect, useState} from "react";
import Layout from "@hoc/layouts/Layout";
import HomeBanner from "@components/Home/HomeBanner";
import HomeLatestNews from "@components/Home/HomeLatestNews";
import HomeProjects from "@components/Home/HomeProjects";
import HomePartners from "@components/Home/HomePartners";
import HomeCards from "@components/Home/HomeCards";
import HomeBudget from "@components/Home/HomeBudget";
import {Link} from "react-router-dom";
import BlankModal from "@core/modal/BlankModal";
import AkukIcon from "@assets/images/homeModal/akuk.png";
import MieIcon from "@assets/images/homeModal/mie.png";
import noticeBg from "@assets/images/notice_bg.png";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {appData} from "@redux/app/Action";

const HomePage = () => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => _.get(state, 'appState.showModal', true))

  return (
    <Layout>
      <HomeBanner />
      <HomeProjects />
      <HomeBudget />
      <HomeLatestNews />
      <HomeCards />
      <HomePartners />
      {showModal && (
        <BlankModal onClose={() => {
          dispatch(appData({showModal: false}))
        }}>
          <div className={'px-5 py-14 w-full'} style={{maxWidth: '500px', backgroundImage: `url(${noticeBg})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
            <div className={'flex items-center mb-10 px-2 justify-center'}>
              <img src={MieIcon} className={'h-13 mr-4'}/>
              <img src={AkukIcon} className={'h-8  ml-4'}/>
            </div>
            <div className={'px-3'}>
              <h1 className={'text-center text-4xl font-extrabold text-white mb-8 italic'}>Njoftim!</h1>
              <p className={'text-justify text-sm font-light text-white my-3'}>Në mbështetje të nenit 101 të Kushtetutës, me propozimin e zëvendëskryeministrit dhe ministër të Infrastrukturës dhe Energjisë, dhe ministrit të Turizmit dhe Mjedisit, Këshilli i Ministrave ka miratuar aktin normativ Nr.8, datë 28.12.2023.</p>
              <p className={'text-justify text-sm font-light text-white my-3'}>Ky akt normativ ka si qëllim trajtimin e ujërave të ndotura nëpërmjet vendosjes së impiantit apo sistemit të grumbullimit, trajtimit dhe largimit të ujërave të ndotura , nga ana e subjekteve, të cilat kryejnë veprimtari ekonomike në zonat bregdetare dhe bregun e liqeneve e të lumenjve dhe nuk i trajtojnë ujërat e ndotura dhe i shkarkojnë ato pranë ose në ujëra larës.</p>
              <p className={'text-center text-xs font-light text-white opacity-80 mt-8'}>
                Për më shumë informacion, referohuni: <br />
                Aktit normativ Nr.8, datë 28.12.2023 si dhe Rregullores Teknike</p>
            </div>
          </div>
        </BlankModal>
      )}
    </Layout>
  );
}

export default HomePage;
