import React from 'react';

const Input = ({label, placeholder, onChange, error, value, containerClass, className, ...props}) => {

    return (
        <div className={`w-full ${containerClass}`}>
          {!!label && (<div className={'w-full text-primary text-sm ml-3'}>{label}</div>)}
          <input
            value={value}
            className={`appearance-none relative block w-full px-5 py-1 border mt-0 border-gray-100 placeholder-gray-400 rounded-full text-gray-800 bg-gray-100 focus:outline-none focus:ring-primary-500 focus:border-indigo-500 focus:z-10 text-lg ${className}`}
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            {...props}
          />
          {error && <div className={`text-xs text-red-400 pt-2 ${!error && 'pb-4'}`}>error</div>}
        </div>
    )
}

export default Input;

