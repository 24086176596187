import React, {useMemo, useState} from "react";
import DownArrow from '@assets/svg/down_arrow.svg';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import _ from "lodash";
import Pagination from "@components/Pages/Pagination";
import {getMediaUrl} from "@utils/helpers";
import ClampLines from "react-clamp-lines";

const PAGE_SIZE = 5;

const Item = ({item = {}}) => {
  const {t, i18n} = useTranslation();

  const language = i18n.language;

  const description = useMemo(() => {
    return item[`body_${language}`]?.substring(0, 200) + '...';
  }, [item, language])

  return (
    <div className={'flex flex-col md:flex-row mt-5 mb-20 md:my-5 px-4 sm:px-0'}>
      <img src={getMediaUrl(item)} className={'w-full md:w-2/5 mx-auto md:mx-0 md:h-64 rounded-2xl shadow-lg object-cover mb-2 md:mb-0'} />
      <div className={"border ml-0 md:ml-2 lg:ml-5 bg-white mx-2 md:mx-0 py-6 md:py-8 px-7 shadow-lg rounded-xl flex-1 flex flex-col justify-between"}>
        <img src={DownArrow} className={'h-5 w-5'} />
        <ClampLines
          id={`media-title-${item.id}`}
          text={item[`title_${language}`]}
          lines={2}
          ellipsis="..."
          className="font-bold my-1"
          innerElement="h1"
          buttons={false}
        />
        <ClampLines
          id={`media-title-${item.id}`}
          text={item[`body_${language}`]}
          lines={4}
          ellipsis="..."
          className="text-gray-700 font-light flex-1"
          innerElement="p"
          buttons={false}
        />
        <div className='mt-4'>
          <Link to={`/media/news/${item.id}`} className={'bg-item-bg hover:bg-green text-gray-700 hover:text-white duration-500 p-2 px-5 text-sm rounded-bl-full rounded-r-full'}>
            {t('readMore')}
          </Link>
        </div>
      </div>
    </div>
  )
}


const MediaNews = () => {
  const data = useSelector((state) =>
    _.get(state, "generalState.news", [])
  );
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);

  return (
    <div>
      {currentTableData.map((item) => (
        <Item key={item.id} item={item} />
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PAGE_SIZE}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}

export default MediaNews;
